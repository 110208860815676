import PrivateClient from './clients/PrivateClient'
import AuthenticatedDownloadClient from './clients/AuthenticatedDownloadClient'
import AuthenticatedClient from './clients/AuthenticatedClient'

const resource = '/private/api'

export default {
  getShips () {
    return PrivateClient.get(`${resource}/por/v1/ships`)
  },
  generateModel (form) {
    return PrivateClient.post(`${resource}/por/v1/model`, form)
  },
  getBatteryInputFile (form) {
    return AuthenticatedDownloadClient.post('/calculate/auth/battery_input_file', form)
  },
  calculateModel (form, formFile) {
    const formData = new FormData()
    Object.keys(form).forEach(key => {
      formData.append(key, form[key])
    })
    formData.append('formFile', formFile.file)
    // console.log(formData)
    return AuthenticatedClient.post('/calculate/auth/calculate_battery_model', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  calculateModelFile (form, formFile) {
    const formData = new FormData()
    Object.keys(form).forEach(key => {
      formData.append(key, form[key])
    })
    formData.append('formFile', formFile.file)
    // console.log(formData)
    return AuthenticatedDownloadClient.post('/calculate/auth/calculate_battery_model_file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
