<template>
  <nav-bar/>
  <aside-menu
    :menu="menu"
    :menu-bottom="menuBottom"
    :class="{ '-left-60 lg:left-0': !isAsideMobileExpanded, 'left-0': isAsideMobileExpanded, 'lg:w-20':isAsideCompact }"
    :label-center="isAsideCompact"
    :compact="isAsideCompact"
    :z-index="secondaryMenuItem ? 'z-40 md:z-50' : 'z-50'"
    @menu-click="menuClick">
    <nav-bar-item v-if="isAsideHiddenLayout" color="" @click="menuClose">
      <icon :path="mdiMenu" class="cursor-pointer" size="24" />
    </nav-bar-item>
    <div class="flex-1" :class="{'px-3':!isAsideHiddenLayout, 'flex justify-center':isAsideCompact}">
      <span v-if="isAsideCompact">
        <profiteia-logo class="h-8" color="white"/>
      </span>
      <profiteia-logo-text class="h-4" v-else />
    </div>
  </aside-menu>
  <aside-menu
    v-if="secondaryMenuItem"
    :menu="secondaryMenuItem.menuSecondary"
    :class="{'md:left-60':!isAsideCompact, 'lg:left-20':isAsideCompact}"
    class="right-0 md:right-auto md:border-l md:border-gray-600 md:dark:border-gray-700">
    <icon v-if="secondaryMenuItem.icon" :path="secondaryMenuItem.icon" w="w-12"/>
    <div class="flex-1">{{ secondaryMenuItem.label }}</div>
    <icon :path="mdiClose" class="cursor-pointer" @click="secondaryMenuClose" w="w-12"/>
  </aside-menu>
  <aside-right/>
  <modal-newsletter/>

  <text-link v-if="updateExists" @click="refreshApp" :label="$t('messages.update_available_click_here_to_apply')" :icon="mdiUpdate" class="text-xs md:text-sm lg:text-base cursor-pointer text-blue-600 underline mx-5 my-5"/>

  <router-view/>
  <footer-bar/>
  <overlay v-show="isOverlayVisible" z-index="z-40" @overlay-click="overlayClick" />
  <snackbar/>
</template>

<script>
// @ is an alias to /src
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { computed, watch, onMounted, ref, onBeforeUnmount } from 'vue'
import { mdiClose, mdiMenu, mdiLifebuoy, mdiRefresh, mdiOpenInNew, mdiInformation, mdiUpdate } from '@mdi/js'
import { useRoute } from 'vue-router'

import menu from '@/menu.js'
import Icon from '@/components/Icon'
import NavBar from '@/components/NavBar'
import Overlay from '@/components/Overlay'
import Snackbar from '@/components/Snackbar'
import TextLink from '@/components/TextLink'
// import JbButton from '@/components/JbButton'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'
import AsideRight from '@/components/AsideRight'
import menuBottom from '@/menuBottom.js'
import NavBarItem from '@/components/NavBarItem'
// import Notification from '@/components/Notification'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import ProfiteiaLogo from '@/components/ProfiteiaLogo'
import ModalNewsletter from '@/components/ModalNewsletter'
import ProfiteiaLogoText from '@/components/ProfiteiaLogoText'

export default {
  name: 'Home',
  components: {
    ModalNewsletter,
    ProfiteiaLogo,
    ProfiteiaLogoText,
    Icon,
    // JbButton,
    FooterBar,
    AsideMenu,
    AsideRight,
    NavBar,
    NavBarItem,
    // Notification,
    Overlay,
    Snackbar,
    TextLink
  },
  setup () {
    const { t } = useI18n()
    const store = useStore()
    const route = useRoute()

    const updateExists = ref(false)
    const registration = ref(false)

    const isAsideCompact = computed(() => store.state.display.isAsideCompact)
    const isAsideVisible = computed(() => store.state.display.isAsideVisible)
    const isAsideHiddenLayout = computed(() => store.state.display.isAsideHiddenLayout)
    const isAsideMobileExpanded = computed(() => store.state.display.isAsideMobileExpanded)
    const isOverlayVisible = computed(() => secondaryMenuItem.value || (isAsideHiddenLayout.value && isAsideVisible.value))

    const secondaryMenuItem = computed({
      get: () => store.state.display.secondaryMenu,
      set: value => store.dispatch('display/secondaryMenuToggle', value)
    })

    const secondaryMenuClose = () => {
      secondaryMenuItem.value = null
    }

    const menuClick = (event, item) => {
      if (item.darkModeToggle) {
        store.dispatch('display/darkMode')
      }

      if (item.menu) {
        store.dispatch('display/asideCompactToggle', false)
      }

      if (item.menuSecondary) {
        if (secondaryMenuItem.value && item.key === secondaryMenuItem.value.key) {
          secondaryMenuClose()
        } else {
          secondaryMenuItem.value = item
        }
      }
    }

    const refreshApp = () => {
      console.log('refreshApp clicked: ', updateExists.value)
      updateExists.value = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!registration.value || !registration.value.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      registration.value.waiting.postMessage({ type: 'SKIP_WAITING' })
      console.log('window.location.reload()')
      window.location.reload()
    }

    const updateAvailable = (event) => {
      registration.value = event.detail
      // updateExists.value = true
      updateExists.value = false
    }

    const menuClose = () => {
      store.dispatch('display/asideVisibilityToggle', false)
      secondaryMenuClose()
    }

    const overlayClick = () => {
      if (secondaryMenuItem.value) {
        secondaryMenuClose()
      } else if (isAsideHiddenLayout.value && isAsideVisible.value) {
        menuClose()
      }
    }

    store.dispatch('display/pushMessage', t('general_welcome_back'))

    onBeforeUnmount(() => {
      document.removeEventListener('swUpdated', updateAvailable)
    })

    onMounted(async () => {
      document.addEventListener('swUpdated', updateAvailable)
      const fp = await FingerprintJS.load()
      const { visitorId } = await fp.get()

      store.commit('auth/setVisitorId', visitorId)
      // console.log(visitorId)
    })

    watch(route, (to, from) => {
      store.commit('auth/setRouteChange', {
        to: to.path,
        from: from.path
      })
      store.dispatch('auth/storeRouteChange')
      store.dispatch('auth/getUserInformation')
    }, { deep: false })

    return {
      menu,
      menuBottom,
      isAsideCompact,
      isAsideVisible,
      isAsideMobileExpanded,
      isAsideHiddenLayout,
      isOverlayVisible,
      secondaryMenuItem,
      menuClick,
      secondaryMenuClose,
      menuClose,
      overlayClick,
      mdiClose,
      mdiMenu,
      mdiLifebuoy,
      updateExists,
      registration,
      mdiOpenInNew,
      mdiRefresh,
      mdiInformation,
      mdiUpdate,
      refreshApp
    }
  }
}
</script>
