<template>
  <hr class="-mx-6 border-gray-100 border-t dark:border-gray-700" :class="my">
</template>

<script>
export default {
  name: 'Divider',
  props: {
    my: {
      type: String,
      default: 'my-6'
    }
  }
}
</script>
