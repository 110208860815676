<template>
  <title-bar :title-stack="titleStack" />
  <hero-bar>
    Admin
    <template #right>
      <jb-button to="/" label="Dashboard"/>
    </template>
  </hero-bar>
  <main-section>
    <title-sub-bar :icon="mdiAccountMultiple" title="Accounts"/>

    <div class="grid grid-cols-1 gap-6 mb-6 xl:grid-cols-1 ">
      <card-component>
        <field label="Filter" multiline>
          <control :icon-left="mdiAccount" help="Your full name" placeholder="Name" v-model="searchString"/>
        </field>

        <divider />

        <field label="Checkbox" multiline>
          <check-radio-picker
            name="sample-checkbox-one"
            v-model="form.checkboxOne"
            :options="checkboxOptions"
          />
        </field>
      </card-component>
    </div>

    <div class="grid grid-cols-1 gap-6 mb-6 xl:grid-cols-1 ">
      <card-component title="Users" :icon="mdiAccountMultiple" class="mb-6 lg:mb-0 lg:col-span-2 xl:col-span-3" has-table>
        <clients-table :searchString="searchString"/>
      </card-component>
    </div>
  </main-section>
</template>

<script>
import { computed, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { mdiMonitorCellphone, mdiAccount, mdiAccountMultiple, mdiTableBorder, mdiTableOff, mdiBallotOutline } from '@mdi/js'
import MainSection from '@/components/MainSection'
// import Notification from '@/components/Notification'
import ClientsTable from '@/components/ClientsTable'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import TitleSubBar from '@/components/TitleSubBar'
import HeroBar from '@/components/HeroBar'
// import CardClientBar from '@/components/CardClientBar'
// import CardTransactionBar from '@/components/CardTransactionBar'
// import CardProductBar from '@/components/CardProductBar'
// import BottomOtherPagesSection from '@/components/BottomOtherPagesSection'
import JbButton from '@/components/JbButton'
import CheckRadioPicker from '@/components/CheckRadioPicker'
import Field from '@/components/Field'
import Divider from '@/components/Divider'
import Control from '@/components/Control'

export default {
  name: 'Tables',
  components: {
    MainSection,
    HeroBar,
    TitleBar,
    TitleSubBar,
    CardComponent,
    ClientsTable,
    // Notification,
    // CardClientBar,
    // CardTransactionBar,
    // CardProductBar,
    // BottomOtherPagesSection,
    JbButton,
    CheckRadioPicker,
    Field,
    Divider,
    Control
  },
  setup () {
    const titleStack = ref(['Admin', 'Tables & Lists'])
    const store = useStore()
    const clientBarItems = computed(() => store.state.data.clients.slice(0, 3))
    const transactionBarItems = computed(() => store.state.data.history.slice(0, 3))
    const productBarItems = computed(() => store.state.data.products.slice(0, 3))

    const selectFieldOptions = [
      { id: 1, label: 'Business development' },
      { id: 2, label: 'Marketing' },
      { id: 3, label: 'Sales' }
    ]

    const checkboxOptions = { is_subscribed: 'Subscription', is_active: 'Active', is_inactive: 'Inactive', is_admin: 'Admin' }

    const searchString = ref('test')

    const form = reactive({
      name: 'John Doe',
      email: 'john.doe@example.com',
      pass: 'secret',
      phone: '',
      amount: 1024,
      ipv6: 'fe80::/10',
      ipv4: '127.0.0.1',
      currency: 'USD',
      department: selectFieldOptions[0],
      question: 'Error state',
      subject: '',
      checkboxOne: ['lorem'],
      checkboxTwo: ['lorem'],
      radioOne: 'one',
      radioTwo: 'one',
      switchOne: ['one'],
      switchTwo: ['one']
    })

    return {
      searchString,
      form,
      titleStack,
      clientBarItems,
      transactionBarItems,
      productBarItems,
      mdiMonitorCellphone,
      mdiAccount,
      mdiAccountMultiple,
      mdiTableBorder,
      mdiTableOff,
      mdiBallotOutline,
      checkboxOptions
    }
  }
}
</script>
