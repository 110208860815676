<template>
  <div ref="target">
    <card-component :icon="cardIcon" :title="title" :sourceHeader="`${$t('general_source')}: ${displayOptions.source}`" footer v-if="displayCard === true" :smallerPadding="true" :footerWithoutBorder="false">
      <highcharts class="chart" :uuid="uuid" :options="localChartOptions" ref="chartRef"></highcharts>
    
      <!-- Modal Box to show when action is not allowed -->
      <modal-box
        v-model="errorModalActive"
        :title="errorButtonLabel"
        button="success"
        :buttonLabel="errorButtonLabel"
        @confirm="$router.push(errorButtonAction)"
        hasCancel
        :text-link-label="$t('general_button_ask_for_help')"
        :text-link-icon="mdiLifebuoy"
        textLinkTo="/contact"
      >      
        <p>{{ errorMessage }}</p>
      </modal-box>

      <!-- Modal Box to show when changing date -->
      <modal-box
        v-model="showCalendar"
        :title="$t('general_select_dates')"
        button="success"
        :button-label="$t('general_save')"
        @confirm="updateDates"
        hasCancel
      >      
        <field>
          <control type="date" v-model="dateSelection.startDate" :help="$t('general_start_date')"/>
        </field>
        <field>
          <control type="date" v-model="dateSelection.endDate" :help="$t('general_end_date')"/>
        </field>
      </modal-box>
      <template #footer>
        <div class="flex space-x-0">
          <text-link v-if="false" :icon="mdiBell" label="Alert" xsmall isPro class="mr-5"/>
          <text-link v-if="allowDynamicDates" :icon="mdiCalendarBlank" @click="showCalendar = !showCalendar" :label="`${$t('general_date')}`" xsmall class="pr-4"/>
          <text-link :icon="mdiContentSave" @click="downloadJPEG" :label="`${$t('general_image')}`" isPro xsmall class="pr-4"/>
          <text-link :icon="mdiContentSave" v-if="enableCSV" @click="downloadCSV" :label="`${$t('general_csv')}`" isPro xsmall />
        </div>
        <div class="flex-initial w-auto">
          <country-dropdown v-if="countrySelectOptions" @updateCountry="updateCountry" :countryOptions="countrySelectOptions" :selectedCountry="selectedCountry" />
        </div>
      </template>
    </card-component>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { useI18n } from 'vue-i18n'
import { v4 as uuidv4 } from 'uuid'

import filters from '@/filters'
import ModalBox from '@/components/ModalBox' 
import TextLink from '@/components/TextLink'
import CardComponent from '@/components/CardComponent'
import ButtonMenu from '@/components/ButtonMenu'

import Field from '@/components/Field'
import Control from '@/components/Control'
import CountryDropdown from '@/components/CountryDropdown'

import { useElementVisibility } from '@vueuse/core'
import { ref, onMounted, watch, computed, defineProps, defineEmits } from 'vue'
import {
  mdiBell,
  mdiCalendarBlank,
  mdiContentSave,
  mdiFinance,
  mdiMoleculeCo2,
  mdiEarth,
  mdiLifebuoy,
  mdiPowerPlug,
  mdiGasCylinder,
  mdiBarrel,
  mdiShipWheel,
  mdiFire
} from '@mdi/js'

const props = defineProps({
  column: {
    type: Object
  }
})

const router = useRouter()
const errorModalActive = ref(false)
const errorMessage = ref('') 
const errorButtonLabel = ref('')
const errorButtonAction = ref('')
const showCalendar = ref(false) 

const { t } = useI18n()
const store = useStore()
const displayCard = ref(true)

// UUID for reference
const uuid = uuidv4()

const series = ref([])
const target = ref(null)
const chartRef = ref(null)
const categories = ref([]) 
const yAxisCategories = ref([])
const updateDate = ref(null) 

// Check if is visible
const targetIsVisible = useElementVisibility(target)

const isAuthenticated = computed(() => store.state.auth.isAuthenticated)
const isSuperUser = computed(() => store.state.auth.isSuperUser)
const isSubscribed = computed(() => store.state.auth.isSubscribed)

// Options from column
const fetchOptions = computed(() => props.column.fetchOptions)
const displayOptions = computed(() => props.column.displayOptions)

const hideCard = (state, event) => {
  displayCard.value = false
}

const dateSelection = ref({
  startDate: fetchOptions.value.start_date ? fetchOptions.value.start_date.split(' ')[0] : undefined,
  endDate: fetchOptions.value.end_date ? fetchOptions.value.end_date.split(' ')[0] : undefined
})

const defaultCountries = { 
  'GIE Countries': [ 
    { label: 'NL', value: 'NL' },
    { label: 'BE', value: 'BE' },
    { label: 'DE', value: 'DE' },
    { label: 'FR', value: 'FR' },
    { label: 'IT', value: 'IT' },
    { label: 'AT', value: 'AT' },
    // { label: 'BG', value: 'BG' },
    // { label: 'CZ', value: 'CZ' },
    { label: 'DK', value: 'DK' },
    { label: 'ES', value: 'ES' },
    // { label: 'GB', value: 'GB' },
    // { label: 'HR', value: 'HR' },
    // { label: 'HU', value: 'HU' },
    // { label: 'IE', value: 'IE' },
    // { label: 'LV', value: 'LV' },
    { label: 'PL', value: 'PL' },
    { label: 'PT', value: 'PT' }
    // { label: 'RO', value: 'RO' },
    // { label: 'SE', value: 'SE' }
  ],
  'ENTSO-E': [
    { label: 'NL', value: 'NL' },
    { label: 'BE', value: 'BE' },
    { label: 'DE', value: 'DE' },
    { label: 'FR', value: 'FR' },
    { label: 'IT', value: 'IT' },
    { label: 'PL', value: 'PL' },
    // { label: 'BG', value: 'BG' },
    // { label: 'HR', value: 'HR' },
    { label: 'AT', value: 'AT' },
    { label: 'CH', value: 'CH' },
    // { label: 'CZ', value: 'CZ' },
    { label: 'ES', value: 'ES' },
    { label: 'PT', value: 'PT' },
    // { label: 'RO', value: 'RO' },
    { label: 'DK', value: 'DK' },
    { label: 'NO', value: 'NO' },
    { label: 'SE', value: 'SE' },
    { label: 'FI', value: 'FI' }
    // { label: 'SI', value: 'SI' },
    // { label: 'RS', value: 'RS' },
    // { label: 'IE', value: 'IE' },
    // { label: 'EE', value: 'EE' },
    // { label: 'LT', value: 'LT' },
    // { label: 'LV', value: 'LV' },
    // { label: 'UA', value: 'UA' }
    // { label: 'MK', value: 'MK' },
    // { label: 'ME', value: 'ME' },
    // { label: 'GR', value: 'GR' },
    // { label: 'HU', value: 'HU' }
  ],
  'ICE Power Base': [
    { label: 'NL', value: 'Dutch Power Base Load Futures' },
    { label: 'AT', value: 'Austrian Power Financial Base Futures' },
    { label: 'BE', value: 'Belgian Power Base Load Futures' },
    { label: 'FR', value: 'French Power Financial Base Futures' },
    { label: 'DE', value: 'German Power Financial Base Futures' },
    { label: 'IT', value: 'Italian Power Financial Base Futures' },
    { label: 'ES', value: 'Spanish Power Financial Base Futures' },
    { label: 'CH', value: 'Swiss Power Financial Base Futures' }
  ],
  'ICE Power Peak': [
    { label: 'NL', value: 'Dutch Power Peak Load (8-20) Futures' },
    { label: 'FR', value: 'French Power Financial Peak Futures' },
    { label: 'DE', value: 'German Power Financial Peak Futures' },
    { label: 'IT', value: 'Italian Power Financial Peak Futures' },
    { label: 'BE', value: 'Belgian Power Financial Peak Futures' },
    { label: 'AT', value: 'Austrian Power Financial Peak Futures' }
  ]
}

function isNullOrUndefined (value) {
  return value === null || value === undefined
}

const syncDimensions = (updatedRef) => {
  if (
    isNullOrUndefined(updatedRef) || 
    isNullOrUndefined(displayOptions.value.syncWhat)) {
    return 
  }

  displayOptions.value.syncWhat.forEach(function (syncWhatDimension, syncWhatIndex) {
    if (
      isNullOrUndefined(updatedRef.chart) || 
      isNullOrUndefined(updatedRef.chart.xAxis) || 
      isNullOrUndefined(updatedRef.chart.yAxis)) {
      return 
    }

    const extremesY = updatedRef.chart.yAxis[0].getExtremes()
    const extremesX = updatedRef.chart.xAxis[0].getExtremes()

    if (
      isNullOrUndefined(extremesY.min) || 
      isNullOrUndefined(extremesY.max) || 
      isNullOrUndefined(extremesX.min) || 
      isNullOrUndefined(extremesX.max)
    ) {
      return
    }

    // console.log('check1', syncWhatIndex, syncWhatDimension, extremesX, extremesY)
    // Get values for syncWhatDimension
    const syncValues = store.getters['display/getSyncValues']({
      uuid: uuid,
      syncId: displayOptions.value.syncId,
      syncWhat: syncWhatDimension
    })

    // console.log(syncValues)

    // Make sure chart is initalized
    if (syncValues.length > 0) {
      const min = Math.min(...syncValues)
      const max = Math.max(...syncValues)
      
      // console.log('Found min: ', min, ' and max: ', max)
      if (syncWhatDimension === 'yMin' && extremesY.min !== min) {  
        updatedRef.chart.yAxis[0].setExtremes(min, extremesY.max)
        updatedRef.chart.redraw()
      } else if (syncWhatDimension === 'yMax' && extremesY.max !== max) {
        // console.log('Updating extremesY.max for ', uuid, ' to gofrom: ', extremesY.max, ' to: ', max)
        updatedRef.chart.yAxis[0].setExtremes(extremesY.min, max)
        updatedRef.chart.redraw()
      } else if (syncWhatDimension === 'xMin' && extremesX.min !== min) {
        // console.log('Updating extremesX.min for ', uuid, ' to gofrom: ', extremesX.min, ' to: ', min)
        updatedRef.chart.xAxis[0].setExtremes(min, extremesX.max)
        updatedRef.chart.redraw()
      } else if (syncWhatDimension === 'xMax' && extremesX.max !== max) {
        // console.log('Updating extremesX.max for ', uuid, ' to gofrom: ', extremesX.max, ' to: ', max)
        updatedRef.chart.xAxis[0].setExtremes(extremesX.min, max)
        updatedRef.chart.redraw()
      }
    }
  })
}

const cardIcon = computed(() => {
  if (displayOptions.value.icon === 'mdiMoleculeCo2') {
    return mdiMoleculeCo2
  } else if (displayOptions.value.icon === 'mdiFire') {
    return mdiFire
  } else if (displayOptions.value.icon === 'mdiBarrel') {
    return mdiBarrel
  } else if (displayOptions.value.icon === 'mdiEarth') {
    return mdiEarth
  } else if (displayOptions.value.icon === 'mdiPowerPlug') {
    return mdiPowerPlug
  } else if (displayOptions.value.icon === 'mdiGasCylinder') {
    return mdiGasCylinder
  } else if (displayOptions.value.icon === 'mdiShipWheel') {
    return mdiShipWheel
  }
  return mdiFinance
})

const dateTimeLabelFormats = {
  millisecond: '%H:%M:%S.%L',
  second: '%H:%M:%S',
  minute: '%H:%M',
  hour: '%H:%M',
  day: '%e. %b',
  week: '%e. %b',
  month: '%b \'%y',
  year: '%Y'
}

// Configure the options for the chart 
const credits = {
  enabled: true,
  href: 'https://profiteia.io',
  text: 'profiteia.io'
}

const events = {
  addSeries: function () {
    const chart = this
    chart.hideLoading(t('loading'))
  },
  load: function () {
    const chart = this
    chart.showLoading(t('loading'))
    
    const dimensions = filters.watermarkDimensions({
      chartWidth: chart.chartWidth,
      chartHeight: chart.chartHeight
    })

    chart.watermark = chart.renderer.image('/images/profiteia_logo_icon.svg', dimensions.svgPosX, dimensions.svgPosY, dimensions.svgWidth, dimensions.svgHeight).add()
  },
  setExtremes: function () {
    console.log('new extremes, calling redraw!')
    this.redraw()
  },
  redraw: function () {
    const chart = this
    const extremesY = chart.yAxis[0].getExtremes()
    const extremesX = chart.xAxis[0].getExtremes()

    syncChart({ height: chart.chartHeight, width: chart.chartWidth, xMin: extremesX.min, xMax: extremesX.max, yMin: extremesY.min, yMax: extremesY.max })

    const dimensions = filters.watermarkDimensions({
      chartWidth: chart.chartWidth,
      chartHeight: chart.chartHeight
    })

    if (chart.watermark) {
      chart.watermark.attr({ x: dimensions.svgPosX, y: dimensions.svgPosY })
    } else if (extremesX.max !== undefined && extremesX.max !== null) {
      chart.watermark = chart.renderer.image('/images/profiteia_logo_icon.svg', dimensions.svgPosX, dimensions.svgPosY, dimensions.svgWidth, dimensions.svgHeight).add()
    }
  }
}

const localChartOptions = computed(() => ({
  chart: {
    styledMode: true,
    backgroundColor: 'transparent',
    type: displayOptions.value.chartType || 'line',
    zoomType: 'x',
    events,
    plotBackgroundImage: 'https://profiteia.io/images/profiteia_logo_icon.svg'
  },
  time: timeOptions.value,
  credits,
  plotOptions: plotOptions.value,
  lang: {
    decimalPoint: '.',
    thousandsSep: ',',
    months: t('months'),
    shortMonths: t('shortMonths'),
    weekdays: t('weekdays')
  },
  backgroundColor: 'transparent',
  exporting: {
    enabled: false,
    enableImages: true,
    chartOptions: {
      title: {
        text: title.value
      },
      chart: {
        backgroundColor: '#FFFFFF',
        events: {
          load: function () {
            this.container.classList.add('export')
          }
        }
      }
    }
  },
  dataLabels: {
    enabled: true,
    color: '#000000'
  },
  tooltip: tooltip.value,
  colors: ['#8FD694', '#8bd600', '#7DBA84', '#77AD78', '#6F8F72', '#504B43'],
  colorAxis: displayOptions.value.colorAxis || undefined,
  title: null, // do not use titles
  series: series.value,
  xAxis: xAxis.value,
  yAxis: yAxis.value
}))

// Computed
const enableCSV = computed(() => {
  return !('disableCSV' in displayOptions.value)
})

const xAxisLabel = computed(() => {
  return ('xAxisLabel' in displayOptions.value) ? t(displayOptions.value.xAxisLabel) : t('chart_labels.date')
})

const yAxisLabel = computed(() => {
  return ('yAxisLabel' in displayOptions.value) ? t(displayOptions.value.yAxisLabel) : ''
})

const disableLazyLoading = computed(() => {
  return ('disableLazyLoading' in displayOptions.value) ? displayOptions.value.disableLazyLoading : false
})

const allowDynamicDates = computed(() => {
  return !('disableDynamicDates' in displayOptions.value)
})

const title = computed(() => {
  let returnString = t(displayOptions.value.title)

  if (isDynamicCountry.value || isDynamicICEIndex.value) {
    returnString += ` (${selectedCountry.value.label})`
  }

  if ('displayDate' in displayOptions.value) {
    // returns: Intraday spread - 2023-10-27 00:00:00.000 
    returnString += ' - ' + fetchOptions.value.start_date.split(/[ T]/)[0]
  }

  return returnString
})

const refreshTime = computed(() => {
  return ('refreshTime' in displayOptions.value) ? displayOptions.value.refreshTime : 60000
})

const isDynamicCountry = computed(() => {
  return ('country_code' in fetchOptions.value && 'countrySelect' in displayOptions.value)
})

const isDynamicICEIndex = computed(() => {
  return ('ice_index' in fetchOptions.value && 'countrySelect' in displayOptions.value)
})

// Generate dropdown for countrySelect 
const countrySelectOptions = computed(() => {
  // Check if we have a countrySelect in displayOptions
  // console.log('hier')
  if (displayOptions.value.countrySelect === undefined) {
    return null
  }
  // console.log('daar')  
  const countryOptions = defaultCountries[displayOptions.value.countrySelect]
  
  let defaultCountry = 'NL' 

  if (isDynamicCountry.value) {
    defaultCountry = fetchOptions.value.country_code
  } 

  if (isDynamicICEIndex.value) { 
    const item = countryOptions.find(obj => obj.value === fetchOptions.value.ice_index)
    defaultCountry = item.label
  }
  
  // set defaultCountry to the first position and NL to second 
  countryOptions.sort((a, b) => {
    if (a.label === defaultCountry) return -1
    if (b.label === defaultCountry) return 1

    if (a.label === 'NL') return -1
    if (b.label === 'NL') return 1

    return 0
  })

  countryOptions.forEach((item, index) => {
    item.id = index
  })

  return countryOptions
})

const selectedCountry = ref(countrySelectOptions.value && countrySelectOptions.value.length > 0 ? countrySelectOptions.value[0] : null)

const updateDates = (value) => {
  fetchData()
}

const updateCountry = (value) => {
  selectedCountry.value = value
  fetchData()
}

const tooltip = computed(() => {
  const tooltipDefault = {
    headerFormat: '<b>{series.name}</b><br>{point.key}: ',
    pointFormat: '{point.y:.2f}'
  }

  if ('tooltip' in displayOptions.value) {  
    return displayOptions.value.tooltip
  }

  return tooltipDefault
})

const timeOptions = computed(() => {
  if ('time' in displayOptions.value) {  
    return displayOptions.value.time
  }
  return null
})

const yAxisMax = computed(() => {
  if ('yAxisMax' in displayOptions.value) {  
    return displayOptions.value.yAxisMax
  }
  return null
})

const plotOptions = computed(() => {
  const plotOptionsDefault = {
    series: {
      connectNulls: displayOptions.value.connectNulls || false,
      lineWidth: 10,
      marker: {
        enabled: false
      }  
    }
  }

  if ('plotOptions' in displayOptions.value) {  
    return displayOptions.value.plotOptions
  }

  return plotOptionsDefault
})

const xAxis = computed(() => { 
  const xAxisDefault = {
    type: displayOptions.value.xAxisType || 'datetime',
    dateTimeLabelFormats: displayOptions.value.dateTimeLabelFormats || dateTimeLabelFormats,
    categories: displayOptions.value.xAxisType === 'category' ? categories.value : undefined,
    title: {
      text: xAxisLabel.value
    }
  }
  
  if ('xAxis' in displayOptions.value) {  
    return displayOptions.value.xAxis
  }

  return xAxisDefault
})

const yAxis = computed(() => { 
  const yAxisDefault = {
    max: yAxisMax.value,
    startOnTick: false,
    title: {
      text: yAxisLabel.value,
      categories: displayOptions.value.yAxisType === 'category' ? yAxisCategories.value : undefined
    }
  }

  if ('yAxis' in displayOptions.value) {  
    const translatedArray = [] 

    if (Array.isArray(displayOptions.value.yAxis)) {
      displayOptions.value.yAxis.forEach(function (yAxisElement) {
        if ('title' in yAxisElement) {
          yAxisElement.title.text = t(yAxisElement.title.text)
        }
        translatedArray.push(yAxisElement)
      })
    }

    return translatedArray
  }

  return yAxisDefault
})

// Function to fetch the results
const fetchData = async () => {
  // console.log(targetIsVisible.value === true || disableLazyLoading.value === true || (updateDate.value !== null && (new Date() - updateDate.value) < refreshTime.value))
  // if not visible, or lazy loading is disabled and has been updated but not less then one minutes ago
  if (targetIsVisible.value === true || disableLazyLoading.value === true || (updateDate.value !== null && (new Date() - updateDate.value) < refreshTime.value)) {
    try { 
      const fetchOptionsValue = fetchOptions.value

      // check if country_code is in fetchOptionsValue and if the countrySelectOptions overrides this value
      if (isDynamicCountry.value) {
        fetchOptionsValue.country_code = selectedCountry.value.value
      }

      if (allowDynamicDates.value) {
        fetchOptionsValue.start_date = dateSelection.value.startDate + ' 00:00:00'
        fetchOptionsValue.end_date = dateSelection.value.endDate + ' 23:59:59'
      }

      if (isDynamicICEIndex.value) {
        fetchOptionsValue.ice_index = selectedCountry.value.value
      }
      // console.log('selectedCountry:', selectedCountry.value.value)
      // console.log('fetchOptionsValue: ', fetchOptionsValue)

      const response = await store.dispatch(props.column.fetchFunction, { payload: fetchOptionsValue, displayOptions: displayOptions.value })

      if (response.series.length !== 0) {
        series.value = response.series 
      } 

      if (series.value[0].data.length === 0) {
        // hideCard()
      }

      if (response.categories !== null) { 
        categories.value = response.categories
      }
      
      // Fix for heatmaps
      if (response.yAxisCategories !== null) { 
        yAxisCategories.value = response.yAxisCategories
        
        if ('legend' in displayOptions.value) {
          chartRef.value.chart.update({
            legend: displayOptions.value.legend,
            yAxis: {
              categories: yAxisCategories.value,
              title: {
                text: t(yAxisLabel.value)
              }
            }
          })
          // chartRef.value.chart.legend = displayOptions.value.legend
        }
      }
    } catch (error) { 
      console.log(error)
    }
  }
}

// Sync after render
const syncChart = (syncDimensions) => {
  // What are you syncing about 
  
  if (!('syncWhat' in displayOptions.value) || !('syncId' in displayOptions.value)) {
    return
  }

  store.dispatch('display/syncChart', { uuid, syncId: displayOptions.value.syncId, syncWhat: displayOptions.value.syncWhat, syncDimensions })
}

// Download functions 
const downloadCSV = () => {  
  if (!isAuthenticated.value) { 
    errorMessage.value = t('messages.account_required')
    errorModalActive.value = true 
    errorButtonLabel.value = t('register_account')
    errorButtonAction.value = '/register'
    return false
  }

  chartRef.value.chart.downloadCSV()
  // if (!isAuthenticated.value) { 
  //   errorButtonLabel.value = t('register_account')
  //   errorButtonAction.value = '/register'
  // } else if (!isSubscribed.value) { 
  //   errorButtonLabel.value = t('menu_subscriptions')
  //   errorButtonAction.value = '/subscriptions'
  // } else {
  //   chartRef.value.chart.downloadCSV()
  // }

  // errorMessage.value = t('messages.subscription_required')
  // errorModalActive.value = true 
  // return false
}
const downloadJPEG = () => {
  if (!isAuthenticated.value) { 
    errorMessage.value = t('messages.account_required')
    errorModalActive.value = true 
    errorButtonLabel.value = t('register_account')
    errorButtonAction.value = '/register'
    return false
  }

  chartRef.value.chart.exportChart({
    type: 'image/png',
    filename: 'chart-image',
    showTable: true,
    chartOptions: {
      backgroundColor: '#FFFFFF',
      chart: {
        events: {
          load: function () {
            this.container.classList.add('export')
          }
        }
      },
      credits
    }
  })
}

// Watch for updatedRef
watch(chartRef, (updatedRef) => {
  if (
    isNullOrUndefined(updatedRef) || 
    isNullOrUndefined(displayOptions.value.syncId) || 
    isNullOrUndefined(displayOptions.value.syncWhat)) {
    return 
  }
  
  if (('syncWhat' in displayOptions.value) && ('syncId' in displayOptions.value)) {
    watch(() => store.state.display.syncedCharts, () => {
      syncDimensions(updatedRef)
    }, { deep: true })
  }
})

// Watch for change in visibility
watch(targetIsVisible, (newValue) => {
  // console.log('targetIsVisible: ', targetIsVisible.value, newValue)
  if (newValue) {
    fetchData()
  }
})

const forcedUpdate = computed(() => store.state.display.forcedUpdate)
watch(forcedUpdate, () => {
  fetchData()
})

// Lifecycle Hook
onMounted(() => {
  fetchData()
})
</script>
