<template>
  <div class="relative font-[sans-serif] inline-flex items-end cursor-pointer pl-5" ref="menuRef">
    <button type="button" class="text-[#333] text-sm border-none outline-none" @click="toggleMenu">
      <div class="flex items-center">
        <img :src="`/images/icons/countries/${selectedCountry.label.toLowerCase()}.webp`" class="w-4"/>
        <span class="text-xs ml-1">
            {{ selectedCountry.label }}
          </span>
        <svg xmlns="http://www.w3.org/2000/svg" class="w-2 fill-[#333] inline ml-1" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M11.99997 18.1669a2.38 2.38 0 0 1-1.68266-.69733l-9.52-9.52a2.38 2.38 0 1 1 3.36532-3.36532l7.83734 7.83734 7.83734-7.83734a2.38 2.38 0 1 1 3.36532 3.36532l-9.52 9.52a2.38 2.38 0 0 1-1.68266.69734z" clip-rule="evenodd" data-original="#000000" />
        </svg>
      </div>
    </button>
    <ul class='absolute shadow-lg bg-white py-2 px-2 z-[1000] min-w-full w-max rounded max-h-96 overflow-auto' v-if="isVisible">
      <li v-for="option in countryOptions" :key="option.label" :value="option" class='py-1 px-2 hover:bg-blue-50 text-black text-sm cursor-pointer' @click="changeCountry(option)">
        <div class="flex items-center">
          <img :src="`/images/icons/countries/${option.label.toLowerCase()}.webp`" class="w-4"/>
          <span class="text-xs ml-1">
            {{ option.label }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, defineProps, ref, computed, defineEmits } from 'vue'
import { onClickOutside } from '@vueuse/core'

const emit = defineEmits(['updateCountry'])

const props = defineProps({
  countryOptions: Array,
  selectedCountry: Object
})

const isVisible = ref(false)
const menuRef = ref(null)

const toggleMenu = () => {
  isVisible.value = !isVisible.value
}

const displayedCountryOptions = computed(() => {
  return props.countryOptions.filter((option) => {
    return option.label !== props.selectedCountry.label
  })
})

onClickOutside(menuRef, (event) => {
  if (isVisible.value) {
    isVisible.value = false
  }
})

const handleEscape = (e) => {
  if (e.key === 'Escape') {
    isVisible.value = false
  }
}

const changeCountry = (selectedCountry) => {
  isVisible.value = false
  emit('updateCountry', selectedCountry)
}

onMounted(() => {
  window.addEventListener('keydown', handleEscape)
})

onUnmounted(() => {
  window.removeEventListener('keydown', handleEscape)
})

</script>
