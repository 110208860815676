<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 726.14894 396.92694">
    <g id="Laag_2" data-name="Laag 2">
      <g id="Final_Profiteia" data-name="Final Profiteia">
        <g>
          <g>
            <path :fill="color" d="M13.13183,277.68034a13.136,13.136,0,0,1-9.75954-21.92355L130.12907,114.91593a13.13588,13.13588,0,0,1,22.14271,4.39281l34.48263,97.12814,143.35-173.40464a13.1359,13.1359,0,0,1,22.50488,3.97927l43.33805,122.20988,42.07584-22.79792a13.13606,13.13606,0,0,1,12.516,23.09923l-55.5807,30.115a13.136,13.136,0,0,1-18.63853-7.15928L335.59162,77.62778,192.24778,251.02487a13.13589,13.13589,0,0,1-22.5033-3.97453L134.896,148.89181,22.9,273.33151A13.10368,13.10368,0,0,1,13.13183,277.68034Z"/>
            <g>
              <circle :fill="color" cx="486.22598" cy="136.08451" r="15.36426"/>
              <circle :fill="color" cx="540.51659" cy="107.75352" r="17.21333"/>
              <circle :fill="color" cx="594.80719" cy="79.42253" r="19.0624"/>
              <circle :fill="color" cx="649.09779" cy="51.09153" r="20.91147"/>
              <circle :fill="color" cx="703.3884" cy="22.76054" r="22.76054"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ProfiteiaLogo',
  props: {
    color: {
      type: String,
      default: 'white'
    }
  },
  setup (props) {

  }
}
</script>
