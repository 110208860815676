import axios from 'axios'
// const crypto = require('crypto')
// import { createHash } from 'crypto'
import { SHA256, enc } from 'crypto-js'

const debug = false
debug && console.log(SHA256('test').toString(enc.Hex))

const generateHash = function (input) {
  return SHA256(input).toString(enc.Hex)
  // return createHash('sha256').update(input).digest('hex')
}

let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

const baseDomain = process.env.VUE_APP_API_BASE_URL
const baseURL = `${baseDomain}/api/v1/`

const request = axios.create({
  baseURL
})

debug && console.log('refreshToken', window.localStorage.getItem('refreshToken'))
debug && console.log('accessToken', window.sessionStorage.getItem('accessToken'))

// add accessToken if defined
request.interceptors.request.use(config => {
  const visitorId = window.localStorage.getItem('visitorId')
  const accessToken = window.sessionStorage.getItem('accessToken')
  const checksum = generateHash(`${config.url},${config.method},${visitorId}`)

  debug && console.log(`accessToken is ${accessToken}`)

  if (accessToken !== null) {
    debug && console.log(`adding accessToken: ${accessToken}`)
    config.headers.Authorization = `Bearer ${accessToken}`        
  }

  debug && console.log(`${config.url},${config.method},${visitorId} = ${checksum}`)
  // config.headers['Content-Type'] = 'multipart/form-data'
  config.responseType = 'blob'
  config.headers.vID = visitorId
  config.headers.Checksum = checksum
  return config
}, (error) => Promise.reject(error))

// refreshToken if necessary
request.interceptors.response.use(function (response) {
  return response
}, function (error) {
  const originalRequest = error.config

  // If TokenExpired then RefreshToken and retry
  console.log(error)
  if (error.response.status === 401 && !originalRequest._retry) {
    debug && console.log('Unauthorized, check if we can Refresh')
    debug && console.log('refreshToken', window.localStorage.getItem('refreshToken'))
    debug && console.log('isRefreshing', window.sessionStorage.getItem('isRefreshing'))
    debug && console.log(window.sessionStorage.getItem('isRefreshing') !== 'True')

    // Called after Refreshed, adds fresh accessToken
    if (window.sessionStorage.getItem('isRefreshing') === 'True') {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject })
      }).then(accessToken => {
        debug && console.log(`Got fresh AccessToken :) ${accessToken}`)
        originalRequest.headers.Authorization = `Bearer ${accessToken}`
        return axios(originalRequest)
      }).catch(err => {
        return Promise.reject(err)
      })
    }

    debug && console.log('Expired AccessToken, refreshing with token..')
    originalRequest._retry = true
    window.sessionStorage.setItem('isRefreshing', 'True')

    return new Promise(function (resolve, reject) {
      const refreshToken = window.localStorage.getItem('refreshToken')
      debug && console.log(`Refreshing with ${refreshToken}`)
      axios.get(`${baseURL}auth/refresh`, { headers: { Authorization: `Bearer ${refreshToken}` } }).then(({ data }) => {
        const accessToken = data.access_token
        debug && console.log(`Got accessToken ${accessToken}`)
        window.sessionStorage.setItem('accessToken', accessToken)
        originalRequest._retry = true
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        originalRequest.headers.Authorization = `Bearer ${accessToken}`
        processQueue(null, accessToken)
        resolve(axios(originalRequest))
        return request(originalRequest)
      }).catch((err) => {
        debug && console.log('Got Error: ', err)
        window.sessionStorage.removeItem('accessToken')
        window.localStorage.removeItem('refreshToken')
        processQueue(err, null)
        reject(err)
      }).finally(() => {
        window.sessionStorage.setItem('isRefreshing', 'False')
      })
    })
  }

  return Promise.reject(error)
})

export default request
