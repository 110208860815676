<template>
  <nav id="nav"
    v-show="isNavBarVisible"
    class="top-0 left-0 right-0 fixed flex bg-white h-14 border-b border-gray-100 z-30 w-screen transition-position
    lg:w-auto lg:items-stretch dark:bg-gray-900 dark:border-gray-900"
  >
    <div class="flex-1 items-stretch flex h-14">
      <nav-bar-item type="flex lg:hidden" p-x="pl-3 pr-0"  @click.prevent="menuToggleMobile">
        <icon :path="menuToggleMobileIcon" size="24" />
      </nav-bar-item>
      <nav-bar-item type="hidden lg:flex" p-x="pl-3 pr-0" @click.prevent="menuToggle">
        <icon :path="menuToggleIcon" size="24" />
      </nav-bar-item>
      <!--
      <nav-bar-item type="hidden lg:flex">
        <control placeholder="Search everywhere..." borderless />
      </nav-bar-item>
      -->
    </div>
    <div class="flex-none items-stretch flex h-14 lg:hidden">
      <!-- <nav-bar-item @click="asideRightToggle" :active="isAsideRightActive">
        <icon :path="mdiBell" :update-mark="updatesUpdateMark" size="20" />
      </nav-bar-item> -->
      <nav-bar-item @click.prevent="toggleLightDark" has-divider>
        <nav-bar-item-label :icon="mdiThemeLightDark"/>
      </nav-bar-item>
      <nav-bar-item @click.prevent="menuNavBarToggle">
        <icon :path="menuNavBarToggleIcon" size="20" />
      </nav-bar-item>
    </div>
    <div
      class="absolute w-screen top-14 left-0 bg-white shadow
        lg:w-auto lg:items-stretch lg:flex lg:flex-grow lg:static lg:border-b-0 lg:overflow-visible lg:shadow-none dark:bg-gray-900"
      :class="{ 'hidden': !isMenuNavBarActive, 'block': isMenuNavBarActive }"
    >
      <div class="max-h-screen-menu overflow-y-auto lg:overflow-visible lg:flex lg:items-stretch lg:justify-end lg:ml-auto">

        <nav-bar-menu has-divider v-if="!isAuthenticated">
          <nav-bar-item-label label="Menu"/>
          <template #dropdown>
            <!-- <nav-bar-item>
              <nav-bar-item-label :icon="mdiEmailNewsletter" label="Newsletter"/>
            </nav-bar-item> -->
            <nav-bar-item to="/">
              <nav-bar-item-label :icon="mdiHome" label="Home"/>
            </nav-bar-item>
            <nav-bar-item to="/register">
              <nav-bar-item-label :icon="mdiAccount" :label="$t('menu_register')"/>
            </nav-bar-item>
            <nav-bar-item to="/about">
              <nav-bar-item-label :icon="mdiInformationOutline" :label="$t('menu_about_us')"/>
            </nav-bar-item>
            <nav-bar-item to="/contact">
              <nav-bar-item-label :icon="mdiEmailOpen" :label="$t('menu_contact')"/>
            </nav-bar-item>
            <nav-bar-menu-divider/>
            <nav-bar-item to="/login">
              <nav-bar-item-label :icon="mdiLogin" :label="$t('menu_login')"/>
            </nav-bar-item>
          </template>
        </nav-bar-menu>

        <nav-bar-menu has-divider v-else>
          <div>
            <span>
              <pill v-if="false && subscriptionPlan" type="success" :text="$t('subscriptions.'+subscriptionPlan)" small/>
              {{ email }}
              <icon v-if="isSuperUser" :path="mdiCrown" class="cursor-pointer" size="12"/>
            </span>
          </div>

          <template #dropdown>
            <nav-bar-item>
              <nav-bar-item-label :icon="mdiHome" label="Home" to="/"/>
            </nav-bar-item>
            <nav-bar-item to="/profile">
              <nav-bar-item-label :icon="mdiAccount" :label="$t('menu_profile')"/>
            </nav-bar-item>
            <nav-bar-item to="/about">
              <nav-bar-item-label :icon="mdiInformationOutline" :label="$t('menu_about_us')"/>
            </nav-bar-item>
            <nav-bar-item>
              <nav-bar-item-label :icon="mdiEmailOpen" :label="$t('menu_contact')" to="/contact"/>
            </nav-bar-item>
            <nav-bar-menu-divider/>
            <nav-bar-item @click.prevent="logoutAction">
              <nav-bar-item-label :icon="mdiLogout" :label="$t('menu_logout')"/>
            </nav-bar-item>
          </template>
        </nav-bar-menu>

        <nav-bar-menu has-divider class="hidden lg:flex">
          <div>
            <span>{{ currentLocale }}</span>
          </div>

          <template #dropdown>
            <nav-bar-item @click.prevent="setLocale('en')" has-divider v-if="$i18n.locale !== 'en'">
              <nav-bar-item-label :icon="mdiFlag" label="English"/>
            </nav-bar-item>
            <nav-bar-item @click.prevent="setLocale('nl')" has-divider v-if="$i18n.locale !== 'nl'">
              <nav-bar-item-label :icon="mdiFlag" label="Nederlands"/>
            </nav-bar-item>
            <!-- <nav-bar-item @click.prevent="setLocale('de')" has-divider v-if="$i18n.locale !== 'de'">
              <nav-bar-item-label :icon="mdiFlag" label="Deutsch"/>
            </nav-bar-item> -->
          </template>
        </nav-bar-menu>

        <nav-bar-menu has-divider class="lg:hidden">
          <nav-bar-item-label :label="$t('menu_language')"/>

          <template #dropdown>
            <nav-bar-item @click.prevent="setLocale('en')" has-divider v-if="$i18n.locale === 'nl'">
              <nav-bar-item-label :icon="mdiEarth" label="English"/>
            </nav-bar-item>
            <nav-bar-item @click.prevent="setLocale('nl')" has-divider v-if="$i18n.locale === 'en'">
              <nav-bar-item-label :icon="mdiEarth" label="Nederlands"/>
            </nav-bar-item>
            <!-- <nav-bar-item @click.prevent="setLocale('de')" has-divider v-if="$i18n.locale == 'de'">
              <nav-bar-item-label :icon="mdiEarth" label="Deutsch"/>
            </nav-bar-item> -->
          </template>
        </nav-bar-menu>

        <nav-bar-menu has-divider class="lg:hidden">
          <nav-bar-item-label :label="$t('menu_display')"/>

          <template #dropdown>
            <nav-bar-item @click.prevent="toggleLightDark" has-divider>
              <nav-bar-item-label :icon="mdiThemeLightDark" :label="$t('menu_darkmode')"/>
            </nav-bar-item>
          </template>
        </nav-bar-menu>

        <nav-bar-item @click.prevent="toggleLightDark" has-divider class="hidden lg:flex">
          <nav-bar-item-label :icon="mdiThemeLightDark" :label="$t('menu_darkmode')" desktop-icon-only/>
        </nav-bar-item>

        <!-- <nav-bar-item @click="asideRightToggle" :active="isAsideRightActive" has-divider desktop-icon-only>
          <nav-bar-item-label :icon="mdiBell" :update-mark="updatesUpdateMark" label="Updates" desktop-icon-only />
        </nav-bar-item> -->

        <!-- <nav-bar-item desktop-icon-only v-if="!isAuthenticated" to="/login/">
          <nav-bar-item-label :icon="mdiLogin"/>
        </nav-bar-item>
        <nav-bar-item @click.prevent="logoutAction" desktop-icon-only v-else>
          <nav-bar-item-label :icon="mdiLogout" label="Log Out" desktop-icon-only/>
        </nav-bar-item> -->
      </div>
    </div>
  </nav>
</template>

<script>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import {
  mdiForwardburger,
  mdiBackburger,
  mdiClose,
  mdiDotsVertical,
  mdiMenu,
  mdiHome,
  mdiCrown,
  mdiCheck,
  mdiAccount,
  mdiCogOutline,
  mdiLogin,
  mdiLogout,
  mdiBell,
  mdiEmailNewsletter,
  mdiThemeLightDark,
  mdiEmailOpen,
  mdiInformationOutline,
  mdiEarth,
  mdiFlag
} from '@mdi/js'
import NavBarItem from '@/components/NavBarItem'
import NavBarItemLabel from '@/components/NavBarItemLabel'
import NavBarMenu from '@/components/NavBarMenu'
import NavBarMenuDivider from '@/components/NavBarMenuDivider'
import Pill from '@/components/Pill'

// import UserAvatar from '@/components/UserAvatar'
import Icon from '@/components/Icon'
// import Control from '@/components/Control'

export default {
  name: 'NavBar',
  components: {
    // Control,
    // UserAvatar,
    NavBarMenu,
    NavBarItem,
    NavBarItemLabel,
    NavBarMenuDivider,
    Icon,
    Pill
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const currentLocale = computed(() => t('language_short'))
    const isAsideRightActive = computed(() => store.state.display.isAsideRightActive)
    const isNavBarVisible = computed(() => !store.state.display.isFormScreen)
    const isAsideMobileExpanded = computed(() => store.state.display.isAsideMobileExpanded)
    const isAsideCompact = computed(() => store.state.display.isAsideCompact)
    const isAsideHiddenLayout = computed(() => store.state.display.isAsideHiddenLayout)
    const firstname = computed(() => store.state.auth.firstname)
    const email = computed(() => store.state.auth.email)
    const isAuthenticated = computed(() => store.state.auth.isAuthenticated)
    const isSuperUser = computed(() => store.state.auth.isSuperUser)
    const isSubscribed = computed(() => store.state.auth.isSubscribed)
    const subscriptionPlan = computed(() => store.state.auth.subscriptionPlan)

    const menuToggleMobileIcon = computed(() => isAsideMobileExpanded.value ? mdiBackburger : mdiForwardburger)

    const menuToggleMobile = () => store.dispatch('display/asideMobileToggle')

    const menuToggleIcon = computed(() => {
      if (isAsideHiddenLayout.value) {
        return mdiMenu
      }

      return isAsideCompact.value ? mdiForwardburger : mdiBackburger
    })

    const menuToggle = () => {
      store.dispatch(isAsideHiddenLayout.value ? 'display/asideVisibilityToggle' : 'display/asideCompactToggle')
    }

    const asideRightToggle = () => {
      updatesClicked.value = true
      store.dispatch('display/asideRightToggle')
    }

    const logoutAction = () => {
      console.log('logout Clicked')
      store.commit('auth/clearTokens')
      store.commit('auth/clearUserData')
      store.dispatch('display/pushMessage', 'Logout successful')
    }

    const setLocale = (locale) => {
      store.dispatch('display/setLocale', locale)
    }

    const isMenuNavBarActive = ref(false)

    const menuNavBarToggleIcon = computed(() => isMenuNavBarActive.value ? mdiClose : mdiDotsVertical)

    const menuNavBarToggle = () => {
      isMenuNavBarActive.value = !isMenuNavBarActive.value
    }

    const updatesClicked = ref(false)

    const updatesUpdateMark = computed(() => !updatesClicked.value && store.state.display.updatesStatus ? 'warning' : null)

    const toggleLightDark = () => {
      store.dispatch('display/darkMode')
    }
    
    onMounted(() => {
      menuToggle()
    })

    return {
      currentLocale,
      firstname,
      email,
      subscriptionPlan,
      isAuthenticated,
      isSubscribed,
      isSuperUser,
      isAsideRightActive,
      isNavBarVisible,
      isAsideMobileExpanded,
      menuToggleMobileIcon,
      menuToggleIcon,
      isMenuNavBarActive,
      menuNavBarToggleIcon,
      isAsideCompact,
      updatesClicked,
      updatesUpdateMark,
      logoutAction,
      menuToggle,
      menuToggleMobile,
      asideRightToggle,
      menuNavBarToggle,
      toggleLightDark,
      setLocale,
      mdiMenu,
      mdiAccount,
      mdiCogOutline,
      mdiLogout,
      mdiLogin,
      mdiHome,
      mdiCrown,
      mdiCheck,
      mdiBell,
      mdiEmailNewsletter,
      mdiThemeLightDark,
      mdiEmailOpen,
      mdiEarth,
      mdiInformationOutline,
      mdiFlag
    }
  }
}
</script>
