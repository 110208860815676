<template>
  <hero-bar>
    <span class="bg-clip-text text-transparent bg-gradient-to-br from-emerald-700 to-emerald-400 font-azonix">
      Profiteia
    </span>
    <p class="text-base font-normal"><icon :path="mdiConnection" size="12"/>{{ $t('general_battery_model_tagline') }}</p>
  </hero-bar>

  <main-section>
    <calculated-model-results :calculatedModel="calculatedModel"/>
    
    <card-component v-if="calculatedModel.length > 0" :icon="mdiChartAreasplineVariant" title="Model, Opgeteld per dag" class="mt-5 mb-5" footer>
      <calculate-model-chart :series="calculatedModelSums"/>
    </card-component>
    <card-component v-if="calculatedModel.length > 0" :icon="mdiChartAreasplineVariant" title="Model, Gemiddeld" class="mt-5 mb-5" footer>
      <calculate-model-chart :series="calculatedModelAvgs"/>
    </card-component>
    <card-component v-if="calculatedModel.length > 0" :icon="mdiChartAreasplineVariant" title="Model, Max" class="mt-5 mb-5" footer>
      <calculate-model-chart :series="calculatedModelMaxs"/>
    </card-component>
    <card-component v-if="errorMessage.length > 0" :icon="mdiShipWheel" title="Model" class="mt-5" footer>
      <pre>
        {{ errorMessage }}
      </pre>
    </card-component>
    <div class="grid grid-cols-2 xl:grid-cols-2 xl:gap-6 mb-6">
      <div class="xl:flex xl:flex-col xl:col-span-1 mb-4 xl:mb-0">
        <card-component :icon="mdiBriefcaseVariant" title="Invoer" footer>
          <field label="Netaansluiting" class="mb-4">
            <field addons>
              <control name="connection_kw" v-model="form.connection_kw" type="number" required expanded first-addon  help="Netaansluiting"/>
              <control type="static" model-value="kW" last-addon/>
            </field>
          </field>
          <field label="Gecontracteerd vermogen" class="mb-0">
            <field addons>
              <control name="contracted_volume_take_kw" v-model="form.contracted_volume_take_kw" type="number" required expanded first-addon help="Leveren"/>
              <control type="static" model-value="kW" last-addon />
            </field>
            <field addons>
              <control name="contracted_volume_feed_kw" v-model="form.contracted_volume_feed_kw" type="number" required expanded first-addon help="Terugleveren"/>
              <control type="static" model-value="kW" last-addon />
            </field>
          </field>
          <field label="Batterij" class="mb-0">
            <field addons>
              <control name="battery_volume_kw" v-model="form.battery_volume_kw" type="number" required expanded first-addon help="Inhoud (kW)"/>
              <control type="static" model-value="kW" last-addon />
            </field>
          
            <field addons>
              <control name="battery_volume_kwh" v-model="form.battery_volume_kwh" type="number" required expanded first-addon  help="Inhoud (kWh)"/>
              <control type="static" model-value="kWh" last-addon />
            </field>
          </field>
          <field label="Eigenschappen batterij" class="mb-0">
            <field addons>
              <control name="battery_dod_percentage" v-model="form.battery_dod_percentage" type="number" required expanded first-addon help="Depth of Discharge"/>
              <control type="static" model-value="%" last-addon />
            </field>
          
            <field addons>
              <control name="battery_rte_percentage" v-model="form.battery_rte_percentage" type="number" required expanded first-addon help="Round trip effiency"/>
              <control type="static" model-value="%" last-addon />
            </field>
          </field>
          <field label="Werkbare inhoud" class="mb-4">
            <field addons>
              <control name="battery_working_volume_kwh" v-model="form.battery_working_volume_kwh" type="number" required expanded first-addon help="Werkbare inhoud"/>
              <control type="static" model-value="kWh" last-addon />
            </field>
          
            <field addons>
              <control name="battery_starting_volume_kwh" v-model="form.battery_starting_volume_kwh" type="number" required expanded first-addon help="Beginstand" />
              <control type="static" model-value="kWh" last-addon />
            </field>
          </field>
        </card-component>
      </div>
      <div class="xl:flex xl:flex-col xl:col-span-1 mb-6 xl:mb-0">
        <card-component :icon="mdiFile" title="Genereer bestand met invoerwaarden" collapsible footer>
          <field label="Genereer bestand met invoerwaarden">
            <field>
              <control name="year" v-model="formGenerate.year" type="number" required help="Kies jaar"/>
            </field>            
          </field>
          <jb-button label="Download bestand" small type="submit" @click="generateSampleModel" :isLoading="isLoadingSampleModel" color="success"/>
        </card-component>
        <card-component :icon="mdiBattery" title="Start berekening" class="mt-5" footer>
          <field help="Upload bestand met invoerwaarden">
            <file-picker v-model="formFile.file" :addon="true" label="Kies bestand"/>
          </field>
          <divider/>
          <jb-button label="Start berekening" type="submit" small :color="colorButton" :disabled="isDisabled" :isLoading="isLoadingCalculatedModel" @click="calculateModel"/>
          <br/><br/>
          <jb-button label="Start berekening en download bestand" type="submit" small :color="colorButton" :disabled="isDisabled" :isLoading="isLoadingCalculatedModelFile" @click="calculateModelFile"/>
        </card-component>
      </div>
    </div>
  </main-section>
</template>

<script setup>
import { ref, reactive, computed, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import Icon from '@/components/Icon'
import { useStore } from 'vuex'
import {
  mdiFile,
  mdiConnection,
  mdiBattery,
  mdiBriefcaseVariant,
  mdiShipWheel,
  mdiChartAreasplineVariant
} from '@mdi/js'

import Repository from '@/repositories/RepositoryFactory'
import Field from '@/components/Field'
import Control from '@/components/Control'
import HeroBar from '@/components/HeroBar'
import JbButton from '@/components/JbButton'
import FilePicker from '@/components/FilePicker'
import Divider from '@/components/Divider'
import MainSection from '@/components/MainSection'
import Notification from '@/components/Notification'
import CardComponent from '@/components/CardComponent'
import CalculateModelChart from '@/components/charts/CalculateModelChart'
import CalculatedModelResults from '@/components/charts/CalculatedModelResults'

const PrivateRepository = Repository.get('private')
const { t } = useI18n()
const store = useStore()
const router = useRouter()
const route = useRoute()

const isLoadingCalculatedModelFile = ref(false)
const isLoadingCalculatedModel = ref(false)
const isLoadingSampleModel = ref(false)

// const calculatedModel = ref([])
const calculatedModel = ref([])
const calculatedModelSums = ref({})
const calculatedModelAvgs = ref({})
const calculatedModelMaxs = ref({})

const sortedCalculatedModel = computed(() => {
  return calculatedModel.value.slice().sort((a, b) => a.title.localeCompare(b.title))
})

const chunkedModel = computed(() => {
  const chunkSize = 2
  return sortedCalculatedModel.value.reduce((accumulator, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize)

    if (!accumulator[chunkIndex]) {
      accumulator[chunkIndex] = []
    }

    accumulator[chunkIndex].push(item)

    return accumulator
  }, [])
})

const calculateMessage = ref('')
const errorMessage = ref('')

const formGenerate = reactive({
  year: 2022
})

const formFile = reactive({
  file: null
})

const isDisabled = computed(() => formFile.file === null)
const colorButton = computed(() => formFile.file === null ? 'light' : 'success')
const isFetchingUser = computed(() => store.state.auth.isFetchingUser)
const isAuthenticated = computed(() => store.state.auth.isAuthenticated)

const form = reactive({
  connection_kw: 10,
  contracted_volume_take_kw: 10,
  contracted_volume_feed_kw: -10,
  battery_volume_kw: 50,
  battery_volume_kwh: 50,
  battery_dod_percentage: 90,
  battery_rte_percentage: 90,
  battery_working_volume_kwh: 45,
  battery_starting_volume_kwh: 20
})

const forceFileDownload = (response, title) => {
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', title)
  document.body.appendChild(link)
  link.click()
}

watch([isFetchingUser], () => {
  fillDashboard()
})

const fillDashboard = () => {
  const requiresAuth = route.meta.requiresAuth || false

  if (isFetchingUser.value === true) {
    return false
  }
  // console.log(requiresAuth, isAuthenticated.value)
  if (requiresAuth.value === true && isAuthenticated.value === false) {
    store.dispatch('display/pushMessage', t('messages.requires_account'))
    setTimeout(() => {
      router.push('/login')
    }, 750)
    return false
  }
}

onMounted(() => {
  store.dispatch('auth/getUserInformation')
  fillDashboard()
})

const formatNumber = (value) => {
  return new Intl.NumberFormat('nl-NL', { style: 'decimal', minimumFractionDigits: 2 }).format(value)
}

const generateSampleModel = () => {
  isLoadingSampleModel.value = true
  calculateMessage.value = '' 
  errorMessage.value = '' 
  
  return PrivateRepository.getBatteryInputFile(formGenerate).then(response => {
    forceFileDownload(response, 'profiteia_model.xlsx')
    store.dispatch('display/pushMessage', 'Sample file gedownload')
  }).catch(function (error) {
    console.log(error)
    isLoadingSampleModel.value = false
  }).finally(() => {
    isLoadingSampleModel.value = false
  })
}

const calculateModel = () => {
  isLoadingCalculatedModel.value = true
  calculatedModel.value = [] 
  calculateMessage.value = '' 
  errorMessage.value = '' 

  return PrivateRepository.calculateModel(form, formFile).then(response => {
    calculatedModel.value = Object.entries(response.data.values).map(([title, value]) => ({ title, value }))

    calculatedModelAvgs.value = JSON.parse(response.data.daily_avgs)
    calculatedModelSums.value = JSON.parse(response.data.daily_sums)
    calculatedModelMaxs.value = JSON.parse(response.data.daily_maxs)
    window.scrollTo(0, 0)
    store.dispatch('display/pushMessage', 'Model gegenereerd')
  }).catch(function (error) {
    console.log(error)
    store.dispatch('display/pushMessage', 'Er ging iets fout, controleer uw invoerbestand')
    errorMessage.value = error
  }).finally(() => {
    isLoadingCalculatedModel.value = false
  })
}

const calculateModelFile = () => {
  isLoadingCalculatedModelFile.value = true

  return PrivateRepository.calculateModelFile(form, formFile).then(response => {
    forceFileDownload(response, 'profiteia_model_file.xlsx')
    store.dispatch('display/pushMessage', 'Model gedownload')
  }).catch(function (error) {
    console.log(error)
    store.dispatch('display/pushMessage', 'Er ging iets fout, controleer uw invoerbestand')
    errorMessage.value = error
  }).finally(() => {
    isLoadingCalculatedModelFile.value = false
  })
}

</script>
