<template>
    <card-component :title="$t('general_latest_tweets')" :icon="mdiTwitter">
      <card-tweet-bar
          v-for="tweet in tweets"
          :key="tweet.id"
          :name="tweet.name"
          :login="tweet.login"
          :text="tweet.text"
          :date="tweet.created_at"
          :url="tweet.url"
          :progress="tweet.progress"/>
    </card-component>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { mdiTwitter } from '@mdi/js'
import { ref, onMounted } from 'vue'
import CardTweetBar from '@/components/CardTweetBar'
import CardComponent from '@/components/CardComponent'
import Repository from '@/repositories/RepositoryFactory'

export default {
  name: 'Contact',
  components: {
    CardComponent,
    CardTweetBar
  },
  setup () {
    const store = useStore()
    const StatusRepository = Repository.get('status')
    const tweets = ref([])
    const isLoading = ref(false)
    const { t } = useI18n()

    const getTweets = () => {
      isLoading.value = true

      StatusRepository.getTweets().then(response => {
        tweets.value = response.data
      }).catch((error) => {
        if (error) {
          store.dispatch('display/pushMessage', t('messages.error_loading_tweets'))
        }
      })
    }

    onMounted(() => {
      // getTweets()
    })

    return {
      isLoading,
      mdiTwitter,
      tweets
    }
  }
}
</script>
