<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton v-slot="{ open }">
        <jb-button
          :label="label"
          :icon="label ? null : icon"
          :icon-right="label ? icon : null"
          :active="open"
          :small="small"
          :icon-w="iconW"
          :icon-h="iconH"
          :icon-size="iconSize"
          :color="color"
          :outline="outline"
        />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        :class="left ? 'left-0' : 'right-0'"
        class="absolute z-50 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1
        ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800 dark:divide-gray-700"
      >
        <div v-for="(optionsGroup, index) in options" :key="index" class="px-1 py-1">
          <MenuItem v-for="option in optionsGroup" :key="option.id" v-slot="{ active }">
            <button
              :class="{'bg-gray-100 dark:bg-gray-700': active}"
              class="group flex rounded-md items-center w-full px-2 py-2 text-sm"
            >
              <icon :path="option.icon" class="mr-3" />
              <span>{{ option.label }}</span>
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { mdiChevronDown } from '@mdi/js'
import Icon from '@/components/Icon'
import JbButton from '@/components/JbButton'

// Define props
const props = defineProps({
  icon: {
    type: String,
    default: mdiChevronDown
  },
  label: String,
  options: Array,
  modelValue: Object,
  left: Boolean,
  iconW: String,
  iconH: String,
  iconSize: String,
  small: Boolean,
  color: {
    type: String,
    default: 'white'
  },
  outline: Boolean
})

// Define emits
const emit = defineEmits(['update:modelValue'])

// Computed value
const computedValue = computed({
  get: () => props.modelValue,
  set: value => {
    emit('update:modelValue', value)
  }
})
</script>
