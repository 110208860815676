<template>
  <component :is="is" :class="componentClass" :href="href" :type="computedType" :to="to">
    <icon v-if="isLoading" :path="iconLoading" :w="iconW" :h="iconH" :size="iconSize" :class="{ 'animate-spin': isLoading }"/>
    <icon v-if="!isLoading && icon" :path="icon" :w="iconW" :h="iconH" :size="iconSize"/>
    <span v-if="label" :class="labelClass">{{ label }}</span>
    <pill v-if="pillText" :text="pillText" :type="pillType" small />
    <icon v-if="iconRight" :path="iconRight" :w="iconW" :h="iconH" :size="iconSize" />
    <tip :tip="tip" :left="tipX === 'left'" :right="tipX === 'right'" :top="tipTop" />
  </component>
</template>

<script>
import { mdiMenuUp, mdiLoading } from '@mdi/js'
import { computed } from 'vue'
import { colorsButtons, colorsButtonsOutline } from '@/colors.js'
import Icon from '@/components/Icon'
import Tip from '@/components/Tip'
import Pill from '@/components/Pill'

export default {
  name: 'JbButton',
  components: {
    Icon,
    Tip,
    Pill
  },
  props: {
    label: [String, Number],
    icon: String,
    isLoading: Boolean,
    iconRight: String,
    iconW: String,
    iconH: String,
    iconSize: [String, Number],
    href: String,
    to: [String, Object],
    type: String,
    iconLoading: {
      type: String,
      default: mdiLoading
    },
    color: {
      type: String,
      default: 'white'
    },
    small: Boolean,
    outline: Boolean,
    active: Boolean,
    activeSoft: Boolean,
    noBorder: Boolean,
    noFocusRing: Boolean,
    as: String,
    tip: String,
    tipX: String,
    tipTop: Boolean,
    roundedFull: Boolean,
    addon: Boolean,
    pillText: String,
    pillType: {
      type: String,
      default: 'info'
    }
  },
  setup (props) {
    const is = computed(() => {
      if (props.as) {
        return props.as
      }

      if (props.to) {
        return 'router-link'
      }

      if (props.href) {
        return 'a'
      }

      return 'button'
    })

    const computedType = computed(() => {
      if (is.value === 'button') {
        return props.type ?? 'button'
      }

      return null
    })

    const iconOnly = computed(() => props.icon && !props.label && !props.iconRight)

    const labelClass = computed(() => {
      return [
        props.small && props.icon ? 'pl-1' : 'pl-2',
        props.small && props.iconRight ? 'pr-1' : 'pr-2'
      ]
    })

    const componentClass = computed(() => {
      const base = [
        'inline-flex',
        'cursor-pointer',
        'justify-center',
        'items-center',
        'whitespace-nowrap',
        'ring-blue-700',
        'focus:outline-none',
        'transition-colors',
        'duration-150',
        props.outline ? colorsButtonsOutline[props.color] : colorsButtons[props.color]
      ]

      if (!props.noBorder) {
        if (props.activeSoft) {
          base.push('border')
        } else {
          base.push('border-2')
        }
      }

      if (props.small) {
        base.push('p-1')
      } else {
        base.push(
          'py-2',
          props.roundedFull ? 'px-6' : 'px-3'
        )
      }

      if (props.tip) {
        base.push('relative')
      }

      if (!props.noFocusRing) {
        base.push('focus:ring')
      }

      if (props.active) {
        base.push('ring')
      }

      if (props.addon) {
        base.push(props.roundedFull ? 'first:rounded-l-full last:rounded-r-full' : 'first:rounded-l last:rounded-r')
      } else {
        base.push(props.roundedFull ? 'rounded-full' : 'rounded')
      }

      return base
    })

    return {
      is,
      iconOnly,
      labelClass,
      computedType,
      componentClass,
      mdiMenuUp,
      mdiLoading
    }
  }
}
</script>
