<template>
  <card-component v-if="calculatedModel.length > 0" :icon="mdiCrown" title="Model" class="mt-5 mb-5" footer>
    <field label="Gelijktijdigheid">
      <field addons>
        <control name="connection_kw" type="number" required expanded first-addon help="Batterij" :modelValue="getValueByTitle('Gelijktijdigheid MW')"/>
        <control type="static" model-value="MW" last-addon/>
      </field>
      <field addons>
        <control name="connection_kw" type="number" required expanded first-addon help="Batterij" :modelValue="getValueByTitle('Gelijktijdigheid MWh')"/>
        <control type="static" model-value="MWh" last-addon/>
      </field>
      <field addons>
        <control name="connection_kw" type="number" required expanded help="C-Factor" :modelValue="getValueByTitle('Gelijktijdigheid C-factor')"/>
      </field>
    </field>
    <field label="Mogelijkheden laden">
      <field addons>
        <control name="connection_kw" type="number" required expanded first-addon help="Mogelijkheden laden" :modelValue="getValueByTitle('Aantal laden aansluiting (som)')"/>
        <control type="static" model-value="kWh" last-addon/>
      </field>
      <field addons>
        <control name="connection_kw" type="number" required expanded first-addon help="Percentage" :modelValue="getValueByTitle('Aantal laden contract (perc. t.o.v. aantal)')"/>
        <control type="static" model-value="%" last-addon/>
      </field>
      <field addons>
        <control name="connection_kw" type="number" required expanded first-addon help="Gemiddeld kW laden" :modelValue="getValueByTitle('Ruimte contract laden (pos. gemiddelde keer vier)')"/>
        <control type="static" model-value="kW" last-addon/>
      </field>
    </field>
    <field label="Mogelijkheden ontladen">
      <field addons>
        <control name="connection_kw" type="number" required expanded first-addon help="Mogelijkheden ontladen" :modelValue="getValueByTitle('Aantal ontladen contract (som)')"/>
        <control type="static" model-value="kWh" last-addon/>
      </field>
      <field addons>
        <control name="connection_kw" type="number" required expanded first-addon help="Percentage" :modelValue="getValueByTitle('Aantal ontladen contract (perc. t.o.v. aantal)')"/>
        <control type="static" model-value="%" last-addon/>
      </field>
      <field addons>
        <control name="connection_kw" type="number" required expanded first-addon help="Gemiddeld kW ontladen" :modelValue="getValueByTitle('Ruimte contract ontladen (pos. gemiddelde keer vier)')"/>
        <control type="static" model-value="kW" last-addon/>
      </field>      
    </field>
  </card-component>  

  <card-component v-if="calculatedModel.length > 0" :icon="mdiDebugStepInto" title="Debug" class="mt-5 mb-5" collapsible footer>
    <table>
      <tr v-for="(chunk, chunkIndex) in chunkedModel" :key="`chunk-${chunkIndex}`">
        <template v-for="item in chunk" :key="`item-${item.title}`">
          <td><span class="font-semibold">{{ item.title }}</span></td>
          <td>&nbsp;</td>
          <td>{{ formatNumber(item.value) }}</td>
        </template>
      </tr>
    </table>
  </card-component>  
</template>

<script setup>
import { ref, computed, defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
import filters from '@/filters'
import Field from '@/components/Field'
import Control from '@/components/Control'

import {
  mdiFile,
  mdiConnection,
  mdiBattery,
  mdiBriefcaseVariant,
  mdiShipWheel,
  mdiCrown,
  mdiDebugStepInto,
  mdiChartAreasplineVariant
} from '@mdi/js'

// Props are declared using defineProps
const props = defineProps({  
  calculatedModel: {
    type: Object,
    default: () => ({})
  }
})

const sortedCalculatedModel = computed(() => {
  return props.calculatedModel.slice().sort((a, b) => a.title.localeCompare(b.title))
})

const chunkedModel = computed(() => {
  const chunkSize = 2
  return sortedCalculatedModel.value.reduce((accumulator, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize)

    if (!accumulator[chunkIndex]) {
      accumulator[chunkIndex] = []
    }

    accumulator[chunkIndex].push(item)

    return accumulator
  }, [])
})

const isLoading = ref(false)
const { t } = useI18n()
const dynamicTitle = ref('')
const chartRef = ref(null)

const formatNumber = (value) => {
  return new Intl.NumberFormat('nl-NL', { style: 'decimal', minimumFractionDigits: 2 }).format(value)
}

const getValueByTitle = (title) => {
  const item = props.calculatedModel.find(item => item.title === title)
  return item ? item.value : null // Returns null if the title is not found
}

</script>
