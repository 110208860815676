<template>
  <main-section v-if="router.currentRoute.value.path === '/'">
    <div class="grid grid-cols-1 gap-6 mb-0 lg:grid-cols-3 ">
      <card-component class="bg-emerald-500 border-none p-0 m-0" :smallerPadding="true" darkBg="bg-emerald-500">
        <span class="block text-1xl font-semibold mt-0 text-white">{{ $t('homepage_highlights.energy_outlook_title') }}</span>
        <div class="flex text-sm mt-3 font-medium text-white">
          {{ $t('homepage_highlights.energy_outlook_description') }}
        </div>
        <div class="flex text-sm mt-5 font-medium text-white">
          <i>{{ $t('homepage_highlights.account_disclaimer') }}</i>
        </div>
        <span class="block text-1xl font-semibold mt-0 text-white pt-5">
          <text-link to="/reports/outlook" :icon="mdiConnection" :label="$t('homepage_highlights.energy_outlook_link')" small class="cursor-pointer text-white border-none"/>
        </span>      
      </card-component>
      <card-component class="bg-indigo-500 border-none p-0 m-0" :smallerPadding="true" darkBg="bg-indigo-500">
        <span class="block text-1xl font-semibold mt-0 text-white">{{ $t('homepage_highlights.customized_solutions') }}</span>
        <div class="flex text-sm mt-3 font-medium text-white">
          {{ $t('homepage_highlights.customized_solutions_description') }}
        </div>
        <span class="block text-1xl font-semibold mt-0 text-white pt-5">
          <text-link to="/contact" :icon="mdiEmailOpen" :label="$t('homepage_highlights.customized_solutions_link')" small class="cursor-pointer text-white border-none"/>
        </span>
      </card-component>
      <card-component class="bg-red-500 border-none p-0 m-0" :smallerPadding="true" darkBg="bg-red-500">
        <span class="block text-1xl font-semibold mt-0 text-white">{{ $t('homepage_highlights.register_account_title') }}</span>
        <div class="flex text-sm mt-3 font-medium text-white">
          {{ $t('homepage_highlights.register_account_description') }}
        </div>
        <span class="block text-1xl font-semibold mt-0 text-white pt-5">
          <text-link to="/register" :icon="mdiAccount" :label="$t('homepage_highlights.register_account_link')" small class="cursor-pointer text-white border-none"/>
        </span>
      </card-component>
    </div>
  </main-section>
</template>

<script setup>
import { useRouter } from 'vue-router'

import {
  mdiConnection,
  mdiEmailOpen,
  mdiAccount
} from '@mdi/js'
import MainSection from '@/components/MainSection'
import TextLink from '@/components/TextLink'
import CardComponent from '@/components/CardComponent'

const router = useRouter()

</script>
