<template>
  <modal-box
    v-model="isNewsletterModalActive"
    :title="$t('general_receive_newsletter')"
    button="success"
    :button-label="$t('general_button_signup_newsletter')"
    :text-link-label="$t('general_button_ask_for_help')"
    text-link-to="/contact"
    :text-link-icon="mdiLifebuoy"
    @cancel="closeModal"
    @confirm="confirmModal"
    has-cancel
  >
    <p>{{ $t('newsletter_introduction') }}</p>
    <field :label="$t('general_name')" :help="$t('general_name_help_required')">
      <control name="firstname" :placeholder="$t('general_firstname')" type="text" required :error="errorEmail" v-model="contactForm.firstname"/>
      <control name="lastname" :placeholder="$t('general_lastname')" type="text" required :error="errorEmail" v-model="contactForm.lastname"/>
    </field>
    <field :label="$t('general_email')" :help="$t('general_email_help_required')">
      <control name="email" type="email" placeholder="" autocomplete="email" required :error="errorEmail" v-model="contactForm.email"/>
    </field>
  </modal-box>
</template>

<script>
import { useStore } from 'vuex'
import { computed, reactive, ref } from 'vue'
import { mdiLifebuoy } from '@mdi/js'
import Field from '@/components/Field'
import Control from '@/components/Control'
import ModalBox from '@/components/ModalBox'
import Repository from '@/repositories/RepositoryFactory'
import { useI18n } from 'vue-i18n'

export default {
  name: 'ModalNewsletter',
  components: {
    Field,
    Control,
    ModalBox
  },
  setup () {
    const { t } = useI18n()
    const store = useStore()
    const isLoading = ref(false)
    const EmailRepository = Repository.get('email')

    const contactForm = reactive({
      firstname: '',
      lastname: '',
      email: store.state.auth.userEmail
    })

    const errorEmail = ref(false)

    // Computed value
    const isNewsletterModalActive = computed({
      get: () => store.state.display.isNewsletterModalActive,
      set: value => store.dispatch('display/newsletterModalToggle', value)
    })

    // Close modal through user action
    const closeModal = (event, item) => {
      store.dispatch('display/newsletterModalToggle', false)
    }

    const confirmModal = (event, item) => {
      isLoading.value = true

      setTimeout(() => {
        isLoading.value = false

        EmailRepository.subscribeNewsletter(contactForm).then(response => {
          store.dispatch('display/pushMessage', t('messages.newsletter_subscription_success'))
          store.dispatch('display/newsletterModalToggle', false)
          contactForm.email = null
        }).catch((error) => {
          store.dispatch('display/pushMessage', error.response.data.detail)
        })
      }, 500)
    }

    return {
      closeModal,
      contactForm,
      confirmModal,
      errorEmail,
      isLoading,
      isNewsletterModalActive,
      mdiLifebuoy
    }
  }
}
</script>
