// import router from './router'
import i18n from '@/i18n'
import {
  mdiAccount,
  mdiAccountCircle,
  mdiLogin,
  mdiChartAreaspline,
  mdiBook,
  mdiHome,
  mdiEmailOpen,
  mdiCogOutline,
  mdiBattery,
  mdiCreditCardCheck,
  mdiBatteryHeartVariant,
  mdiBellAlert,
  mdiBatteryAlert
} from '@mdi/js'

export default [
  i18n.global.t('menu_dashboards'),
  [
    {
      to: '/',
      label: 'menu_home',
      icon: mdiHome
    },
    // {
    //   to: '/members',
    //   label: 'menu_members',
    //   auth: true,
    //   icon: mdiGasCylinder
    // },
    // {
    //   to: '/subscribers',
    //   label: 'menu_subscribers',
    //   auth: true,
    //   subscribed: true,
    //   icon: mdiGasCylinder
    // },
    {
      to: '/reports/outlook',
      icon: mdiChartAreaspline,
      label: 'menu_energy_outlook'
    },
    {
      to: '/reports/batteries',
      icon: mdiBattery,
      label: 'menu_batteries'
    },
    {
      to: '/reports/battery-simulation',
      icon: mdiBatteryHeartVariant,
      label: 'menu_battery_simulation'
    }
    // },
    // {
    //   to: '/reports/battery-calculation',
    //   icon: mdiBatteryAlert,
    //   label: 'menu_battery_calculation'
    // }
  ],
  i18n.global.t('menu_general'),
  [
    {
      to: '/login',
      icon: mdiLogin,
      label: 'menu_login',
      auth: false
    },
    {
      to: '/profile',
      icon: mdiAccountCircle,
      label: 'menu_profile',
      auth: true
    },
    // {
    //   to: '/register',
    //   icon: mdiAccount,
    //   label: 'menu_register',
    //   updateMark: 'success',
    //   auth: false
    // },
    // {
    //   to: '/subscriptions',
    //   label: 'menu_subscriptions',
    //   icon: mdiCreditCardCheck
    // },
    {
      to: '/about',
      label: 'menu_about_us',
      icon: mdiBook
    },
    // {
    //   to: '/alerts',
    //   label: 'menu_alerts',
    //   icon: mdiBellAlert
    // },
    {
      to: '/contact',
      icon: mdiEmailOpen,
      label: 'menu_contact'
    },
    {
      to: '/admin',
      icon: mdiCogOutline,
      label: 'menu_admin',
      auth: true,
      admin: true
    }
  ]
  // ],
  // 'Examples',
  // [
  //   {
  //     to: '/charts',
  //     label: 'Charts',
  //     icon: mdiTable,
  //     updateMark: 'success'
  //   },
  //   {
  //     to: '/tables-and-lists',
  //     label: 'Tables & Lists',
  //     icon: mdiTable
  //   },
  //   {
  //     to: '/forms',
  //     label: 'Forms',
  //     icon: mdiSquareEditOutline
  //   },
  //   {
  //     to: '/ui',
  //     label: 'UI',
  //     icon: mdiTelevisionGuide
  //   },
  //   {
  //     label: 'Dark mode',
  //     icon: mdiThemeLightDark,
  //     darkModeToggle: true
  //   },
  //   {
  //     to: '/pricing',
  //     label: 'Pricing',
  //     icon: mdiBarcode
  //   },
  //   {
  //     to: '/profile',
  //     label: 'Profile',
  //     icon: mdiAccountCircle
  //   },
  //   {
  //     to: '/login',
  //     label: 'Login',
  //     icon: mdiLock
  //   }
  // ],
  // 'Subs',
  // [
  //   {
  //     label: 'Dropdown',
  //     icon: mdiMenuOpen,
  //     menu: [
  //       {
  //         label: 'Sub-item One'
  //       },
  //       {
  //         label: 'Sub-item Two'
  //       }
  //     ]
  //   },
  //   {
  //     // Key should be unique for each submenus object
  //     // It is required for open/close logic
  //     key: 'submenus-1',
  //     label: 'Submenus',
  //     icon: mdiViewList,
  //     menuSecondary: [
  //       'Something',
  //       [
  //         {
  //           icon: mdiViewList,
  //           label: 'Sub-item One'
  //         },
  //         {
  //           icon: mdiViewList,
  //           label: 'Sub-item Two'
  //         }
  //       ],
  //       'Dropdown',
  //       [
  //         {
  //           label: 'Submenus',
  //           icon: mdiViewList,
  //           menu: [
  //             {
  //               label: 'Sub-item One'
  //             },
  //             {
  //               label: 'Sub-item Two'
  //             }
  //           ]
  //         }
  //       ]
  //     ]
  //   }
  // ],
]
