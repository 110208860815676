<template>
  <div :class="{'relative':button}">
    <img src="/images/profiteia_logo_twitter.png" :alt="name" class="block h-auto w-full max-w-full"/>
    <div v-if="button" class="absolute right-0 bottom-0">
      <file-picker :icon="mdiCamera" rounded-full />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { mdiCamera } from '@mdi/js'
import FilePicker from '@/components/FilePicker'

export default {
  name: 'UserAvatar',
  components: {
    FilePicker
  },
  props: {
    username: String,
    button: Boolean,
    bg: {
      type: String,
      default: 'bg-gray-100 dark:bg-transparant'
    },
    api: {
      type: String,
      default: 'api/avataaars'
    }
  },
  setup (props) {
    const store = useStore()

    const avatar = computed(() => props.username
      ? `https://avatars.dicebear.com/${props.api}/${props.username.replace(/[^a-z0-9]+/i, '-')}.svg`
      : store.state.auth.userAvatar)

    const name = computed(() => props.username ? props.username : store.state.auth.userName)

    return {
      name,
      avatar,
      mdiCamera
    }
  }
}
</script>
