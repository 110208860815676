<template>
  <section class="bg-white border-t border-b border-gray-100 p-6 dark:bg-gray-900 dark:border-gray-900 dark:text-white">
    <level>
      <h1 class="text-3xl font-semibold leading-tight">
        <slot />
      </h1>
      <slot name="right" />
    </level>
  </section>
</template>

<script>
import Level from '@/components/Level'

export default {
  name: 'HeroBar',
  components: {
    Level
  }
}
</script>
