<template>
  <div class="root">
    <div class="grid grid-cols-1 gap-6 mb-6 lg:grid-cols-2">
      <card-component v-if="includeDatePicker" isPro :icon="mdiSelectColor" class="mb-2" :header="false"> 
        <field :label="$t('choose_day')">
          <control type="date" v-model="startDate"/>
        </field>
      </card-component>
    </div>
    
    <div v-for="(row, rowIndex) in typeSchema" :key="rowIndex">
      <title-sub-bar v-if="row.title" :icon="cardIcon(row.title.icon)" :title="$t(row.title.text)"/>
      <div v-if="row.columns.length === 1 && row.columns[0].isSingle === true" class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 xl:gap-6 mb-6">
        <div class="xl:flex xl:flex-col lg:col-span-1 xl:col-span-1 mb-6 xl:mb-0" v-for="(column, columnIndex) in row.columns" :key="columnIndex">
          <dynamic-card :key="columnIndex" :column="column" ref="cardRefs"/>
        </div>
      </div>
      <div v-else :class="`grid grid-cols-1 lg:grid-cols-${row.columns.length} xl:grid-cols-${row.columns.length} xl:gap-6 mb-6`">
        <div class="xl:flex xl:flex-col lg:col-span-1 xl:col-span-1 mb-6 xl:mb-0" v-for="(column, columnIndex) in row.columns" :key="columnIndex">
          <dynamic-card :key="columnIndex" :column="column" ref="cardRefs"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { ref, watch } from 'vue'
import Field from '@/components/Field'
import Control from '@/components/Control'
import DynamicCard from '@/components/DynamicCard'
import TitleSubBar from '@/components/TitleSubBar'

import {
  mdiAccount,
  mdiFinance,
  mdiCog,
  mdiMoleculeCo2,
  mdiMenu,
  mdiFire,
  mdiEarth,
  mdiPowerPlug,
  mdiGasCylinder,
  mdiBarrel,
  mdiShipWheel,
  mdiSelectColor,
  mdiLifebuoy,
  mdiBroadcast,
  mdiPlusCircle
} from '@mdi/js'

export default {
  name: 'DynamicRenderer',
  emits: ['syncChart', 'updateDate'],
  props: {
    userDate: {
      type: String,
      default: null
    },
    typeSchema: {
      type: Array
    },
    data: {
      type: Object
    },
    includeDatePicker: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TitleSubBar,
    DynamicCard,
    Control,
    Field
  },
  setup (props, { emit }) {
    const cardIcon = (icon) => {
      if (icon === 'mdiMoleculeCo2') {
        return mdiMoleculeCo2
      } else if (icon === 'mdiFire') {
        return mdiFire
      } else if (icon === 'mdiBarrel') {
        return mdiBarrel
      } else if (icon === 'mdiEarth') {
        return mdiEarth
      } else if (icon === 'mdiPowerPlug') {
        return mdiPowerPlug
      } else if (icon === 'mdiGasCylinder') {
        return mdiGasCylinder
      } else if (icon === 'mdiShipWheel') {
        return mdiShipWheel
      }
      return mdiFinance
    }

    const today = new Date()
    const formattedDate = props.userDate ? props.userDate : today.toISOString().split('T')[0] 
    const startDate = ref(formattedDate)
    const endDate = ref(null)

    const updateDate = () => {
      const regex = /^\d{4}-\d{2}-\d{2}$/
      if (!regex.test(startDate.value)) {
        console.log('invalid dates')
        return
      }

      const startDateTime = startDate.value + 'T00:00:00.000'
      const endDateTime = startDate.value + 'T23:59:59.999'
      emit('updateDate', [startDateTime, endDateTime])
    }
    
    watch(startDate, value => {
      updateDate()
    })

    return {
      mdiAccount,
      mdiFinance,
      mdiCog,
      mdiMoleculeCo2,
      mdiMenu,
      mdiFire,
      mdiEarth,
      mdiPowerPlug,
      mdiShipWheel,
      mdiSelectColor,
      mdiLifebuoy,
      mdiBroadcast,
      mdiPlusCircle,
      cardIcon, 
      updateDate,
      startDate,
      endDate
    }
  }
}
</script>
