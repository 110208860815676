<template>
  <card-component :icon="cardIcon" :title="$t(displayOptions.title)" class="news-table" :sourceHeader="`${$t('general_source')}: ${$t(displayOptions.source)}`" footer v-if="displayCard === true" :smallerPadding="true" :footerWithoutBorder="false">
    <table class="table-auto">
      <tbody>
        <tr v-for="item in itemsPaginated" :key="item.timestamp">
          <td class="text-xs whitespace-nowrap w-5 pt-2">{{ formatTimestamp(item.timestamp) }}</td>
          <td>
            <text-link :href="item.link" :label="item.title" target="_blank" xsmall :alignLeft="true"/>
          </td>          
          <td v-if="showSourceColumn" class="text-xs">
            <pill type="light" :text="$t('sources.' + item.source)" outline small/>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="table-pagination" v-if="showPagination">
      <level mobile>
        <jb-buttons glue>
          <jb-button
            v-for="page in pagesList"
            @click="currentPage = page"
            :outline="darkMode"
            :active-soft="page === currentPage"
            :key="page"
            :label="page + 1"
            addon
            small
            no-focus-ring
          />
        </jb-buttons>
        <small>{{ $t('general_page') }} {{ currentPageHuman }} {{ $t('general_page_of') }} {{ numPages }}</small>
      </level>
    </div>
  </card-component>
</template>

<script setup>
import 'leaflet/dist/leaflet.css'
import { useI18n } from 'vue-i18n'
import CardComponent from '@/components/CardComponent'
import { ref, onMounted, computed, defineProps } from 'vue'
import Repository from '@/repositories/RepositoryFactory'
import { mdiNewspaper } from '@mdi/js'
import Pill from '@/components/Pill'
import Level from '@/components/Level'
import JbButton from '@/components/JbButton'
import TextLink from '@/components/TextLink'
import JbButtons from '@/components/JbButtons'
const props = defineProps({
  column: {
    type: Object
  }, 
  perPage: {
    type: Number,
    default: 10
  }
})

// Options from column
const displayOptions = computed(() => props.column.displayOptions)
const { t } = useI18n()
const displayCard = ref(true)
const items = ref([])
const StatisticsRepository = Repository.get('statistics')

const currentPage = ref(0)
const numPages = computed(() => Math.ceil(items.value.length / showPerPage.value))
const currentPageHuman = computed(() => currentPage.value + 1)

const showTime = computed(() => {
  return ('showTime' in displayOptions.value) ? displayOptions.value.showTime : true
})

const showPagination = computed(() => {
  return ('showPagination' in displayOptions.value) ? displayOptions.value.showPagination : true
})

const showPerPage = computed(() => {
  return ('perPage' in displayOptions.value) ? t(displayOptions.value.perPage) : props.perPage
})

const itemsPaginated = computed(
  () => items.value.slice(showPerPage.value * currentPage.value, showPerPage.value * (currentPage.value + 1))
)

const pagesList = computed(() => {
  const pagesList = []

  for (let i = 0; i < numPages.value; i++) {
    pagesList.push(i)
  }

  return pagesList
})

const formatTimestamp = (ts) => {
  const date = new Date(ts)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')

  if (showTime.value) {
    return `${year}-${month}-${day} ${hours}:${minutes}`
  }
  
  return `${year}-${month}-${day}`
}

const showSourceColumn = computed(() => {
  return !(props.column.fetchFunction !== undefined && props.column.fetchFunction === 'getTennetOperationalReports')
})

const retrieveNews = async () => {
  if (props.column.fetchFunction !== undefined && props.column.fetchFunction === 'getTennetOperationalReports') { 
    const response = await StatisticsRepository.getTennetOperationalReports()
    items.value = response.data
  } else {
    const response = await StatisticsRepository.getNews()
    items.value = response.data
  }
}

onMounted(() => {
  // console.log(props.column)
  retrieveNews()
})

const cardIcon = computed(() => {
  const icons = {
    mdiNewspaper
  }
  return icons[displayOptions.value.icon] || mdiNewspaper
})
</script>
