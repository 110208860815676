<template>
  <hero-bar>
    <h1 class="text-2xl">Profiteia.io</h1>
    <p class="text-base"><icon :path="mdiConnection" size="12"/>Port of Rotterdam Model</p>
  </hero-bar>

  <main-section>
    <div class="grid grid-cols-1 xl:grid-cols-2 xl:gap-6 mb-6">
      <div class="xl:flex xl:flex-col xl:col-span-1 mb-6 xl:mb-0">
        <card-component :icon="mdiBriefcaseVariant" title="Invoer" footer>
          <field label="Gekozen jaar en maximale plekken" help="Nodig voor het aantal dagen in het jaar">
            <control name="variable_year" v-model="form.variable_year" type="number" required/>
            <control name="variable_maximal_occupancy" v-model="form.variable_maximal_occupancy" type="number" required/>
          </field>
          <field label="Aansluiting (MW) en bezetting (%)" help="Gegevens over aansluiting en bezetting">
            <control name="variable_kw_max" placeholder="in MW" v-model="form.variable_kw_max" type="number" required/>
            <control name="variable_percentage_occupancy_rate" v-model="form.variable_percentage_occupancy_rate" placeholder="bezettingsgraad" type="number" required/>
          </field>
          <field label="Instellingen">
            <check-radio-picker
              name="buttons-switch"
              type="switch"
              v-model="form.variable_limit_on_maximum"
              :options="{ outline: 'Limiteer op maximale bezetting'}"
            ></check-radio-picker>
          </field>

          <jb-button v-if="totalChances === 100" label="Genereer Model" type="submit" color="success" :isLoading="isLoading" @click="generateModel"/>
          <notification v-if="totalChances !== 100" color="warning" class="mt-5">
            <b>Optelsom van kansen</b> is geen 100% maar {{ totalChances }}%
            <template #right></template>
          </notification>
        </card-component>
        <br/>
        <card-component :icon="mdiBook" title="Type schepen" footer>
          <img src="/images/ships-reference-chart.png"/>
        </card-component>
      </div>
      <div class="xl:flex xl:flex-col xl:col-span-1 mb-6 xl:mb-0">
        <card-component :icon="mdiShipWheel" title="Kansverdeling" footer>
          <field v-for="(ship, index) in ships" :key="index">
            <control name="ship_type" type="static" :modelValue="`${ship.Type} (${ship.Variant})`" inputW="w-full text-sm" disabled required/>
            <control name="ship_percentage" v-model="form.chances[ship.Id]" type="number" required/>
          </field>
        </card-component>
      </div>
    </div>

    <div class="grid grid-cols-1 xl:grid-cols-2 xl:gap-6 mb-6" v-if="model.length > 0">
      <div class="xl:flex xl:flex-col xl:col-span-1 mb-6 xl:mb-0">
        <card-component :icon="mdiCurrentAc" title="Gemodelleerd Vermogen" footer>
          <por-model-occupancy-chart :model="model" showType="current" :form="form"/>
        </card-component>
      </div>
      <div class="xl:flex xl:flex-col xl:col-span-1 mb-6 xl:mb-0">
        <card-component :icon="mdiCodeBraces" title="Gemodelleerde Bezetting" footer>
          <por-model-occupancy-chart :model="model" showType="count" :form="form"/>
        </card-component>
      </div>
    </div>

    <div class="grid grid-cols-1 xl:grid-cols-2 xl:gap-6 mb-6" v-if="model.length > 0">
      <div class="xl:flex xl:flex-col xl:col-span-1 mb-6 xl:mb-0">
        <card-component :icon="mdiCog" title="Debug" footer>
          <download-csv v-if="model" :data="model">
            <jb-button label="Download Data" type="submit" color="success"/>
          </download-csv>
          <br/>
          <pre>{{ debug }}</pre>
        </card-component>
      </div>
    </div>
  </main-section>
</template>

<script>
// @ is an alias to /src
import { ref, onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import Icon from '@/components/Icon'
import {
  mdiCurrentAc,
  mdiCurrentDc,
  mdiCodeBraces,
  mdiConnection,
  mdiBriefcaseVariant,
  mdiFire,
  mdiShipWheel,
  mdiBook,
  mdiInformationOutline,
  mdiCog
} from '@mdi/js'

import Notification from '@/components/Notification'
import MainSection from '@/components/MainSection'
import HeroBar from '@/components/HeroBar'
import Field from '@/components/Field'
import Control from '@/components/Control'
import CardComponent from '@/components/CardComponent'
import CheckRadioPicker from '@/components/CheckRadioPicker'
import Repository from '@/repositories/RepositoryFactory'
import JbButton from '@/components/JbButton'
import PorModelOccupancyChart from '@/components/charts/PorModelOccupancyChart'

const PrivateRepository = Repository.get('private')

export default {
  name: 'Dashboard',
  components: {
    Field,
    Control,
    Icon,
    MainSection,
    HeroBar,
    CardComponent,
    CheckRadioPicker,
    JbButton,
    PorModelOccupancyChart,
    Notification
  },
  setup () {
    const store = useStore()
    const chartData = ref(null)
    const isLoading = ref(false)
    const ships = ref([])
    const model = ref({})
    const debug = ref({})

    const form = reactive({
      variable_limit_on_maximum: false,
      variable_year: 2023,
      variable_maximal_occupancy: 4,
      variable_kw_max: 10,
      variable_percentage_occupancy_rate: 10,
      chances: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        17: 0,
        18: 0,
        19: 0,
        20: 0,
        21: 0,
        22: 0,
        23: 0
      }
    })

    const totalChances = computed(() => Object.values(form.chances).reduce((a, b) => a + b, 0))

    const getShips = () => {
      isLoading.value = true

      return PrivateRepository.getShips().then(response => {
        ships.value = response.data
        isLoading.value = false
      }).catch(function (error) {
        console.log(error)
        isLoading.value = false
      })
    }

    const generateModel = () => {
      isLoading.value = true
      model.value = []
      debug.value = {}

      return PrivateRepository.generateModel(form).then(response => {
        model.value = response.data.model.rows
        debug.value = response.data.model.debug
      }).catch(function (error) {
        console.log(error)
        isLoading.value = false
      })
    }

    onMounted(() => {
      getShips()
    })

    return {
      chartData,
      debug,
      mdiCog,
      mdiCurrentAc,
      mdiCurrentDc,
      mdiCodeBraces,
      mdiConnection,
      mdiFire,
      mdiBriefcaseVariant,
      mdiBook,
      mdiShipWheel,
      ships,
      generateModel,
      form,
      model,
      mdiInformationOutline,
      totalChances
    }
  }
}
</script>
