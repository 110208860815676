<template>
  <hero-bar>
    <span class="bg-clip-text text-transparent bg-gradient-to-br from-emerald-700 to-emerald-400 font-azonix">
      Profiteia
    </span>
    <p class="text-base font-normal"><icon :path="mdiConnection" size="12"/>{{ $t('general_tagline') }}</p>
    
    <template #right>
      <jb-button v-if="receivesNewsletter !== true"
        color="success"
        :icon="mdiEmailNewsletter"
        :label="$t('general_receive_newsletter')"
        @click="isNewsletterModalActive = true"
      />
    </template>
  </hero-bar>
  
  <title-bar v-if="titleStack.length > 0" :title-stack="titleStack" />
  <highlighted-cards/> 
  
  <main-section>
    <!-- <ul class="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
      <li class="me-2" v-for="(categoryCount, categoryTitle ) in displayCategories" :key="`m-${categoryTitle}`">
        <a @click="displayedCategory = categoryTitle" class="inline-block px-4 py-3 rounded-lg text-emerald-800 bg-emerald-500 mr-2 cursor-pointer hover:text-gray-900 hover:bg-emerald-400 dark:hover:bg-emerald-400 dark:hover:text-white">
          <span :class="{ 'text-white': displayedCategory === categoryTitle }">
            {{ $t(categoryTitle) }} ({{ categoryCount }})
          </span>
        </a>
      </li>
    </ul> -->

    <dynamic-render :userDate="userDate" :includeDatePicker="includeDatePicker" :refreshKey="refreshKey" v-if="showDynamicRender" :typeSchema="typeSchemaRender" @updateDate="updateDate"/>
  </main-section>
</template>

<script setup>
import { ref, computed, watch, nextTick, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import filters from '@/filters'
import Icon from '@/components/Icon'
import {
  mdiConnection,
  mdiEmailNewsletter
} from '@mdi/js'
import MainSection from '@/components/MainSection'
import HeroBar from '@/components/HeroBar'
import DynamicRender from '@/components/DynamicRender'
import TitleBar from '@/components/TitleBar'
import HighlightedCards from '@/components/HighlightedCards'
import JbButton from '@/components/JbButton'

const { t } = useI18n()
const store = useStore()
const router = useRouter()
const route = useRoute()

const userDate = ref(null)
const typeSchema = ref({})
const requiresAuth = ref(false) // ref(props.requiresAuth || false)
const refreshKey = ref(0)
const receivesNewsletter = computed(() => store.state.auth.receivesNewsletter)
const isNewsletterModalActive = computed({
  get: () => store.state.display.isNewsletterModalActive,
  set: value => store.dispatch('display/newsletterModalToggle', value)
})
const titleStack = ref([])
const isFetchingUser = computed(() => store.state.auth.isFetchingUser)
const isAuthenticated = computed(() => store.state.auth.isAuthenticated)
const typeSchemaRender = ref([])
const includeDatePicker = ref(false)
const showDynamicRender = ref(true)
const isLg = computed(() => store.state.isLg)
const isMd = computed(() => store.state.isMd)
const forcedUpdate = computed(() => store.state.display.forcedUpdate)

// const displayCategories = computed(() => {
//   const categoryCounts = {}
  
//   typeSchemaRender.value.forEach(row => {
//     if (row.columns === undefined) {
//       return
//     }
    
//     for (const [key, column] of Object.entries(row.columns)) {
//       const category = column.displayOptions.category !== undefined ? column.displayOptions.category : 'categories.undefined'
      
//       if (categoryCounts[category] === undefined) {
//         categoryCounts[category] = 1
//       } else {
//         categoryCounts[category]++
//       }
//     }
//   })
  
//   return categoryCounts
// })

// const typeSchemaRenderFiltered = computed(() => {
//   const filtered = typeSchemaRender.value.filter(row => {
//     if (row.columns === undefined) {
//       return true
//     }
    
//     for (const [key, column] of Object.entries(row.columns)) {
//       if (displayedCategory.value === '') {
//         return true
//       }
      
//       const category = column.displayOptions.category !== undefined ? column.displayOptions.category : 'categories.undefined'
      
//       if (category === displayedCategory.value) {
//         return true
//       }
//     }
    
//     return false
//   })
  
//   return filtered
// })

// const displayedCategory = ref('')

// watch(displayCategories, (newValue) => {
//   displayedCategory.value = Object.keys(newValue)[0]
// })

watch(route, () => {
  typeSchema.value = route.meta.typeSchema || {}
  requiresAuth.value = route.meta.requiresAuth || false 
  fillDashboard()
})

watch([isLg, isMd, isFetchingUser], () => {
  typeSchema.value = route.meta.typeSchema || {}
  fillDashboard()
})

onMounted(() => {
  typeSchema.value = route.meta.typeSchema || {}
  fillDashboard()
})

watch(() => typeSchema, () => {
  fillDashboard()
})

const fillDashboard = () => {
  requiresAuth.value = route.meta.requiresAuth || false
  refreshKey.value++
  typeSchemaRender.value = []
  showDynamicRender.value = false

  if (isFetchingUser.value === true) {
    return false
  }
  // console.log(requiresAuth, isAuthenticated.value)
  if (requiresAuth.value === true && isAuthenticated.value === false) {
    store.dispatch('display/pushMessage', t('messages.requires_account'))
    setTimeout(() => {
      router.push('/login')
    }, 750)
    return false
  } else { 
    titleStack.value = 'title' in typeSchema.value ? [t('menu_home'), t(typeSchema.value.title)] : []
    includeDatePicker.value = 'includeDatePicker' in typeSchema.value
    typeSchemaRender.value = filters.fillDates(typeSchema.value.data)

    nextTick(() => {
      showDynamicRender.value = true
    })
  }
}

const updateDate = (item) => { 
  const newTypeSchema = route.meta.typeSchema

  typeSchema.value.data.forEach(row => {
    for (const [key, column] of Object.entries(row.columns)) {
      if (column.fetchOptions.start_date !== undefined || column.fetchOptions.end_date !== undefined) {
        column.fetchOptions.start_date = item[0]
        column.fetchOptions.end_date = item[1]
      }

      newTypeSchema[key] = column
    }
  })
  
  userDate.value = item[0].split('T')[0]
  typeSchema.value = newTypeSchema
  fillDashboard()
}
</script>
