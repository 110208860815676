import AuthenticatedClient from './clients/AuthenticatedClient'
import Client from './clients/Client'

const resource = '/statistics'

export default {
  getGIEForCountry (payload) {
    return Client.post(`${resource}/gie`, payload)
  },
  getENTSOEDayAheadMovingAverageForCountry (payload) {
    return Client.post(`${resource}/entsoe/day-ahead-prices/moving-average`, payload)
  },
  getICE (payload) { 
    return Client.post(`${resource}/ice`, payload)
  },
  getICEDaily (payload) { 
    return Client.post(`${resource}/ice/daily`, payload)
  },
  getICELive () { 
    return Client.get(`${resource}/ice/live/dutch-ttf`)
  },
  getICESettlements (payload) { 
    return Client.post(`${resource}/ice/settlements`, payload)
  },
  getGeneric (payload) { 
    return Client.post(`${resource}/generic`, payload)
  },
  getGenericSpread (payload) { 
    return Client.post(`${resource}/generic/spread`, payload)
  },
  getTennetSettlementPricesMovingAverage () {
    return Client.get(`${resource}/tennet/settlementprices/moving-average`)
  },
  getTennetSettlementPricesHeatmap () {
    return Client.get(`${resource}/tennet/settlementprices/heatmap`)
  },
  getSensorData () {
    return Client.get(`${resource}/sensors`)
  },
  getSensorDataMinute () {
    return Client.get(`${resource}/sensors/60`)
  },
  getMultiGasPrices (payload) {
    return Client.post(`${resource}/multi/gas`, payload)
  },
  getENTSOGConsumption (payload) {
    return Client.post(`${resource}/entsog/consumption`, payload)
  },
  getNews () {
    return Client.get(`${resource}/news`)
  },
  getTennetOperationalReports () { 
    return Client.get(`${resource}/news/tennet/operational`)
  }
}
