<template>
  <modal-box
    v-model="isModalActive"
    title="Please confirm action"
    text-link-label="Need Help?"
    :text-link-icon="mdiLifebuoy"
    has-cancel
  >
    <p>Lorem ipsum dolor sit amet <b>adipiscing elit</b></p>
    <p>This is sample modal</p>
  </modal-box>
  {{ searchString }}
  <table>
    <thead>
    <tr>
      <th @click="greet" sort-by="lastname" class="underline">Name</th>
      <th @click="greet" sort-by="email" class="underline">E-mail</th>
      <th @click="greet" sort-by="last_login" class="underline">Last Login</th>
      <th>Tags</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="client in itemsPaginated" :key="client.id">
      <td data-label="Name">{{ client.lastname }}, {{ client.firstname }}</td>
      <td data-label="Email">{{ client.email }}</td>
      <td data-label="Last Login">
        <pill v-if="client.is_active" type="success" :text="client.last_login" small/>
      </td>
      <td data-label="Tags">
        <pill v-if="client.subscription" type="success" text="subscribed" small/>
        <pill v-if="client.is_active" type="success" text="active" small/>
        <pill v-if="client.is_superuser" type="danger" text="admin" small/>
      </td>
      <td class="actions-cell">
        <jb-buttons type="justify-end" no-wrap>
          <jb-button :icon="mdiEye" color="success" @click="isModalActive = true" small />
          <jb-button :icon="mdiTrashCan" color="danger" @click="isModalActive = true" small />
        </jb-buttons>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="table-pagination">
    <level mobile>
      <jb-buttons glue>
        <jb-button
          v-for="page in pagesList"
          @click="currentPage = page"
          :outline="darkMode"
          :active-soft="page === currentPage"
          :key="page"
          :label="page + 1"
          addon
          small
          no-focus-ring
        />
      </jb-buttons>
      <small>Page {{ currentPageHuman }} of {{ numPages }}</small>
    </level>
  </div>
</template>

<script>
import { computed, watch, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { mdiEye, mdiTrashCan, mdiLifebuoy } from '@mdi/js'
import ModalBox from '@/components/ModalBox'
// import CheckboxCell from '@/components/CheckboxCell'
import Level from '@/components/Level'
import JbButtons from '@/components/JbButtons'
import JbButton from '@/components/JbButton'
import Repository from '@/repositories/RepositoryFactory'
import Pill from '@/components/Pill'

export default {
  name: 'ClientsTable',
  components: {
    ModalBox,
    // CheckboxCell,
    Level,
    Pill,
    JbButtons,
    JbButton
  },
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    searchString: {
      type: String,
      default: ''
    },
    checkable: Boolean,
    perPage: {
      type: Number,
      default: 10
    }
  },
  setup (props) {
    const store = useStore()
    const AuthRepository = Repository.get('auth')
    const darkMode = computed(() => store.state.display.darkMode)

    // const items = computed(() => store.state.data.clients)
    const items = ref([])
    const isModalActive = ref(false)

    const currentPage = ref(0)
    const checkedRows = ref([])

    const itemsPaginated = computed(
      () => items.value.slice(props.perPage * currentPage.value, props.perPage * (currentPage.value + 1))
    )

    const isActive = false 

    const filteredItems = computed(() => {
      // If the searchString is empty, return all items
      if (!this.searchString.trim() && !isActive.value) {
        return this.items
      }

      // Convert the searchString to lowercase for a case-insensitive search
      const searchTerm = this.searchString.toLowerCase()

      // Filter the items based on the searchString and isActive
      return items.value.filter(item => {
        const matchesSearch = item.firstname.toLowerCase().includes(searchTerm) ||
                              item.lastname.toLowerCase().includes(searchTerm) ||
                              item.email.toLowerCase().includes(searchTerm)
        const matchesActiveStatus = !isActive.value || item.is_active
        
        return matchesSearch && matchesActiveStatus
      })
    })

    const numPages = computed(() => Math.ceil(items.value.length / props.perPage))

    const currentPageHuman = computed(() => currentPage.value + 1)

    const pagesList = computed(() => {
      const pagesList = []

      for (let i = 0; i < numPages.value; i++) {
        pagesList.push(i)
      }

      return pagesList
    })

    const remove = (arr, cb) => {
      const newArr = []

      arr.forEach(item => {
        if (!cb(item)) {
          newArr.push(item)
        }
      })

      return newArr
    }

    const checked = (isChecked, client) => {
      if (isChecked) {
        checkedRows.value.push(client)
      } else {
        checkedRows.value = remove(checkedRows.value, row => row.id === client.id)
      }
    }

    const getUsersAdministration = () => {
      AuthRepository.getUsersAdministration().then((response) => {
        items.value = response.data.users
        console.log(items.value)
      }).catch((error) => {
        throw error
      })
    }

    function greet (event) {
      alert(`Hello ${name.value}!`)
      // `event` is the native DOM event
      if (event) {
        console.log(event.target.getAttribute('sort-by'))
      }
    }

    watch(() => props.searchString, (first, second) => {
      getUsersAdministration()
    })

    onMounted(() => {
      getUsersAdministration()
    })

    return {
      darkMode,
      greet,
      isModalActive,
      currentPage,
      currentPageHuman,
      numPages,
      checkedRows,
      itemsPaginated,
      pagesList,
      checked,
      mdiEye,
      mdiTrashCan,
      mdiLifebuoy
    }
  }
}
</script>
