<template>
  <highcharts class="chart" :options="highChartOptions" ref="chartRef"></highcharts>
</template>

<script setup>
import { ref, computed, defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
import filters from '@/filters'

// Props are declared using defineProps
const props = defineProps({
  xAxisLabel: {
    type: String,
    default: 'chart_labels.date'
  },
  yAxisLabel: {
    type: String,
    default: 'chart_labels.euro_per_mwh'
  },
  series: {
    type: Object,
    default: () => ({})
  }
})
const isLoading = ref(false)
const { t } = useI18n()
const dynamicTitle = ref('')
const chartRef = ref(null)

const highchartsSeries = computed(() => {
  const data = props.series
  const seriesData = {}

  for (const [timestamp, values] of Object.entries(data)) {
    for (const [key, value] of Object.entries(values)) {
      if (!seriesData[key]) {
        seriesData[key] = []
      }
      seriesData[key].push([parseInt(timestamp), value])
    }
  }

  return Object.keys(seriesData).map(key => ({
    name: key,
    data: seriesData[key]
  }))
})

const highChartOptions = computed({
  get () {
    return {
      chart: {
        styledMode: true,
        type: 'line',
        zoomType: 'xy',
        events: {
          redraw: function () {
            const chart = this
            const extremesY = chart.yAxis[0].getExtremes()
            const extremesX = chart.xAxis[0].getExtremes()

            const dimensions = filters.watermarkDimensions({
              chartWidth: chart.chartWidth,
              chartHeight: chart.chartHeight
            })

            if (chart.watermark) {
              chart.watermark.attr({ x: dimensions.svgPosX, y: dimensions.svgPosY })
            } else if (extremesX.max !== undefined && extremesX.max !== null) {
              chart.watermark = chart.renderer.image('/images/profiteia_logo_icon.svg', dimensions.svgPosX, dimensions.svgPosY, dimensions.svgWidth, dimensions.svgHeight).add()
            }
          }
        },
        exporting: {
          enabled: false
        }
      },
      credits: {
        enabled: false,
        href: 'https://profiteia.io',
        text: 'profiteia.io'
      },
      title: {
        // text: t(props.title),
        text: undefined
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: t(props.xAxisLabel)
        }
      },
      yAxis: [
        {
          title: {
            text: 'Waarde'
          }
        },
        {
          title: {
            text: dynamicTitle.value
          },
          opposite: true
        }
      ],
      tooltip: {
        pointFormat: '{point.x:%e. %b}: {point.y:.2f}'
      },
      series: highchartsSeries.value,
      plotOptions: {
        column: {
          minPointLength: 3
        },
        series: {
          color: '#FF0000',
          marker: {
            enabled: false
          }
        }
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          }
        }]
      }
    }
  }
})

</script>

<style type="text/css">
.highcharts-plot-line {
  color: red;
  height: 2em;
  width: 2em;
}
</style>
