export default {
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "choose_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Tag"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden..."])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test"])},
  "register_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto registrieren"])},
  "language_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
  "language_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "language_dutch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländisch"])},
  "language_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
  "general_welcome_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen zurück!"])},
  "general_latest_tweets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neueste Tweets"])},
  "general_tweets_view_on_twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Twitter ansehen"])},
  "general_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle"])},
  "general_source_various": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle: Verschiedene"])},
  "general_click_to_show_or_hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken zum Anzeigen oder Verstecken"])},
  "general_current_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "general_current_new_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
  "general_current_new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "general_current_new_password_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
  "general_password_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "general_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "general_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
  "general_email_help_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mail-Adresse"])},
  "general_firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
  "general_lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
  "general_button_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "general_button_ask_for_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brauchen Sie Hilfe?"])},
  "general_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betreff"])},
  "general_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frage"])},
  "general_question_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Frage"])},
  "general_question_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie können wir Ihnen helfen?"])},
  "general_captcha_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füllen Sie die Zeichen unten aus"])},
  "general_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "general_name_help_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Name"])},
  "general_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
  "general_message_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht gesendet"])},
  "general_tagline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energie und Daten verbinden"])},
  "general_receive_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter erhalten"])},
  "general_read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr lesen"])},
  "general_read_more_about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über Profiteia"])},
  "general_button_signup_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "general_reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
  "general_resend_activation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivierung erneut senden"])},
  "general_back_to_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum Login"])},
  "general_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
  "general_updated_automatically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch aktualisiert"])},
  "general_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
  "general_button_contact_us_with_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragen? Kontaktieren Sie uns"])},
  "menu_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnements"])},
  "menu_dashboards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboards"])},
  "menu_general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemein"])},
  "menu_energy_outlook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energieausblick"])},
  "menu_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
  "menu_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "menu_batteries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batterieausblick"])},
  "menu_battery_simulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batteriesimulation"])},
  "menu_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "menu_display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige"])},
  "menu_about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])},
  "menu_darkmode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dunkel/Hell"])},
  "menu_gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
  "menu_carbon_dioxide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2"])},
  "menu_oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öl"])},
  "menu_electricity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strom"])},
  "menu_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "menu_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
  "menu_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "menu_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "menu_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "menu_subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnieren"])},
  "menu_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder"])},
  "menu_pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisgestaltung"])},
  "menu_subscribers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnenten"])},
  "card_title_ice_eua_futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICE EUA Futures"])},
  "card_title_entsoe_day_ahead_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTSO-E Day Ahead Preise"])},
  "row_titles": {
    "mains_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzfrequenz"])},
    "gas_storage_and_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasspeicherung und -verwendung"])},
    "oil_gas_and_co2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öl, Gas & CO2"])},
    "eu_allowance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EU Zertifikate"])},
    "gas_and_power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas & Strom"])},
    "gas_and_power_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländische Gas- & Strompreise"])},
    "power_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strom Base Load Futures Niederlande"])},
    "coal_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohlepreise"])},
    "oil_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ölpreise"])},
    "gas_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaspreise"])},
    "ttf_gas_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTF Gaspreise"])},
    "european_energy_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strom Base Load Futures Europa"])},
    "dutch_day_ahead_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländische Day Ahead Preise"])},
    "european_day_ahead_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day Ahead Preise Europa"])},
    "spark30s_atlantic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spark30S Atlantic Sabine Pass to Gate LNG Freight Future"])},
    "spark25s_pacific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spark25S Pacific NWS to Tianjin LNG Freight Future"])}
  },
  "card_titles": {
    "ttf_versus_jkm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTF versus JKM"])},
    "world_gas_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weltweite Gaspreise"])},
    "temperature_and_wind_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind und Temperatur (NL)"])},
    "solar_impact_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solarer Einfluss pro Stunde (NL)"])},
    "price_ladder_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisleiter Heute"])},
    "price_ladder_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisleiter Letzte Woche"])},
    "wind_speed_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windgeschwindigkeit (m/s) (NL)"])},
    "day_ahead_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day-Ahead-Preise"])},
    "wind_and_solar_forecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind- und Solarprognose"])},
    "electricy_forecast_and_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prognose und Last kombiniert"])},
    "electricy_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stromverbrauch"])},
    "electricy_generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stromerzeugung"])},
    "forecasted_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prognostizierte Last"])},
    "generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erzeugung"])},
    "balance_delta_and_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilanzdifferenz und Preise"])},
    "imbalance_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unausgeglichene Preise"])},
    "capacity_price_ladder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapazitätspreisleiter"])},
    "capacity_price_ladder_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapazitätspreisleiter Heute"])},
    "capacity_price_ladder_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapazitätspreisleiter Letzte Woche"])},
    "spread_settlement_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread-Abrechnungspreise"])},
    "spreads_combined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kombinierte Spreads"])},
    "intraday_spread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intraday-Spread"])},
    "day_ahead_spread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day-Ahead-Spread"])},
    "sum_of_upwards_and_downwards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe der Auf- und Abregelungen"])},
    "activated_balancing_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivierte Ausgleichsenergiepreise"])},
    "afrr_netherlands_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Niederlande Monatlich"])},
    "afrr_netherlands_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Niederlande Jährlich"])},
    "mfrr_netherlands_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Niederlande Monatlich"])},
    "mfrr_netherlands_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Niederlande Jährlich"])},
    "mfrr_netherlands_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Niederlande Letzte Woche"])},
    "afrr_netherlands_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Niederlande Letzte Woche"])},
    "fcr_netherlands_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Niederlande Monatlich"])},
    "fcr_netherlands_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Niederlande Jährlich"])},
    "fcr_netherlands_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Niederlande Heute"])},
    "fcr_netherlands_last_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Niederlande Letzte Woche"])},
    "fcr_netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Niederlande"])},
    "mains_frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzfrequenz"])},
    "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über Profiteia"])},
    "multi_gas_price_world": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weltweite Gaspreise"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
    "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil bearbeiten"])},
    "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns"])},
    "agsi_gas_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiken Gaslagerung"])},
    "alsi_gas_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiken LNG-Lagerung"])},
    "multi_gas_prices_map_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weltweite Gaspreise (€/MWh)"])},
    "gie_gas_storage_map_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaslager Prozentwerte"])},
    "dutch_ttf_gas_base_load_futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländische TTF-Gas-Grundlast-Futures"])},
    "dutch_ttf_gas_base_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländische TTF-Gas-Grundlast-Termingeschäfte"])},
    "dutch_ttf_gas_base_load_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländische TTF-Gas-Grundlast-Abrechnungen"])},
    "dutch_ttf_live_frontmonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländisches TTF-Gas Frontmonat"])},
    "dutch_ttf_live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländisches TTF-Gas"])},
    "dutch_ttf_gas_base_load_front_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländisches TTF-Gas Frontmonat"])},
    "brent_crude_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brent-Rohöl-Termingeschäft"])},
    "brent_crude_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brent-Rohöl-Abrechnungen"])},
    "combined_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kombinierte Abrechnungen"])},
    "rotterdam_coal_futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotterdamer Kohle-Futures"])},
    "rotterdam_coal_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotterdamer Kohle-Termingeschäft"])},
    "rotterdam_coal_front_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotterdamer Kohle Frontmonat"])},
    "rotterdam_coal_frontmonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotterdamer Kohle Frontmonat"])},
    "rotterdam_coal_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotterdamer Kohle-Abrechnungen"])},
    "ice_eua_futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUA-Futures"])},
    "ice_eua_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUA-Termingeschäft"])},
    "ice_eua_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUA-Abrechnungen"])},
    "ice_eua_front_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUA Frontmonat"])},
    "dutch_power_base_load_futures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländische Strom-Grundlast-Futures"])},
    "dutch_power_peak_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländische Strom-Spitzenlast-Futures"])},
    "dutch_power_off_peak_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländische Strom-Nebenlast-Futures"])},
    "dutch_power_base_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländische Strom-Grundlast-Termingeschäfte"])},
    "dutch_power_base_load_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländische Strom-Grundlast-Abrechnungen"])},
    "italian_power_base_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italienische Strom-Grundlast-Futures"])},
    "german_power_base_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsche Strom-Grundlast-Futures"])},
    "french_power_base_load_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französische Strom-Grundlast-Futures"])},
    "dutch_day_ahead_prices_moving_average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EPEX-Spot DAM"])},
    "dutch_day_ahead_prices_week_heatmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siebentägige DA-Preise"])},
    "tennet_settlementprices_seven_week_heatmap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sieben Wochen Unausgeglichene Preise"])},
    "tennet_settlementprices_imbalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TenneT Unausgeglichene Preise MA"])},
    "tennet_imbalance_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TenneT Unausgeglichene Preise"])},
    "dutch_day_ahead_prices_yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländische DA-Preise Gestern"])},
    "dutch_day_ahead_prices_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländische DA-Preise Heute"])},
    "dutch_day_ahead_prices_tomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländische DA-Preise Morgen"])},
    "dutch_day_ahead_prices_last_two_weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei Wochen NL DA"])},
    "german_day_ahead_prices_last_two_weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei Wochen DE DA"])},
    "spark25s_pacific_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacific NWS zu Tianjin LNG-Fracht-Termingeschäft"])},
    "spark25s_pacific_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacific NWS zu Tianjin LNG-Fracht-Abrechnungen"])},
    "spark30s_atlantic_forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlantic Sabine Pass zu Gate LNG-Fracht-Termingeschäft"])},
    "spark30s_atlantic_settlements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlantic Sabine Pass zu Gate LNG-Fracht-Abrechnungen"])},
    "entsog_month_and_year_average_netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasverbrauch Niederlande"])},
    "tennet_settlementprices_take": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unausgeglichene Preise für Mangel"])},
    "entsoe_contracted_reserves_sums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrahierte Volumen für Ausgleich"])},
    "tennet_settlementprices_monthly_sums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realisierte Abrechnungspreise"])},
    "entsoe_reserved_balancing_reserves_sums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservierte Ausgleichsreserven"])},
    "entsoe_activated_balancing_prices_sums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivierte Ausgleichsenergiepreise"])},
    "gie_gas_storage_in_netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasspeicher in den Niederlanden"])}
  },
  "newsletter_introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie unten Ihre Daten ein, um unseren Newsletter zu erhalten. Sie können sich jederzeit von unserem Newsletter abmelden."])},
  "newsletter_teaser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bleiben Sie auf dem Laufenden mit unseren Marktinformationen und Plattform-Updates"])},
  "newsletter_call_to_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter und Updates erhalten"])},
  "information_card_subscribers_welcome_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur für Abonnenten"])},
  "information_card_subscribers_welcome_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Bereich unserer Website ist nur für Abonnenten von Profiteia.io!"])},
  "information_card_welcome_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen bei"])},
  "information_card_welcome_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiteia.io bietet Einblicke in (Entwicklungen im) europäischen Energiemarkt. Mit einem Fokus auf erneuerbare Energien und Maßnahmen zur Steigerung der Energieeffizienz hoffen wir, den Energieübergang zu stärken. Derzeit befindet sich die Website in aktiver Entwicklung und nur einige Teile sind verfügbar (für die Öffentlichkeit)."])},
  "information_card_sign_up_for_our_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melden Sie sich für unseren Newsletter an"])},
  "information_card_interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interessiert"])},
  "register_already_have_an_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie bereits ein Konto?"])},
  "register_forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
  "resend_activation_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivierungslink erneut senden"])},
  "messages": {
    "account_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Funktionalität ist nur für registrierte Benutzer."])},
    "subscription_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Funktionalität ist nur für registrierte Benutzer mit einem aktiven Abonnement."])},
    "invalid_refresh_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung abgelaufen"])},
    "invalid_access_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung abgelaufen"])},
    "session_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung abgelaufen"])},
    "password_changed_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort geändert. Bitte melden Sie sich erneut an."])},
    "contact_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns, um Ihren Account zu aktivierenResendActivationCode"])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite nicht gefunden"])},
    "page_not_found_instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfen Sie Ihre URL oder kontaktieren Sie uns!"])},
    "requires_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Seite erfordert ein Konto bei Profiteia"])},
    "account_not_yet_activated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto ist nicht aktiviert. Überprüfen Sie Ihre E-Mail."])},
    "update_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update verfügbar!"])},
    "click_here_to_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte hier klicken, um es anzuwenden"])},
    "update_available_click_here_to_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update verfügbar! Klicken zum Anwenden"])},
    "invalid_captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges CAPTCHA"])},
    "error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "check_your_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie Ihre E-Mail"])},
    "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie Ihre E-Mail, um Ihr Konto zu bestätigen"])},
    "check_your_email_to_reactivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie Ihre E-Mail, um Ihr Konto zu bestätigen"])},
    "registration_confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie Ihre E-Mail, um Ihr Konto zu bestätigen"])},
    "login_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung erfolgreich, Weiterleitung zur Startseite"])},
    "enter_email_and_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte E-Mail-Adresse und Passwort eingeben"])},
    "invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige E-Mail-Adresse"])},
    "invalid_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Passwort"])},
    "passwords_do_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Passwörter stimmen nicht überein"])},
    "newsletter_subscription_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben sich erfolgreich für unseren Newsletter angemeldet"])},
    "incorrect_email_or_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige E-Mail oder Passwort"])},
    "error_loading_tweets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Laden von Tweets"])}
  },
  "chart_labels": {
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
    "avg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnitt"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
    "hourly_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stundenpreis"])},
    "front_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontmonat"])},
    "front_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frontjahr"])},
    "day_ahead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day Ahead"])},
    "temperature_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatur (NL)"])},
    "temperature_and_wind_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind und Temperatur (NL)"])},
    "solar_impact_nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solareinfluss pro Stunde (NL)"])},
    "price_ladder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisleiter"])},
    "price_ladder_today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisleiter Heute"])},
    "datetime_trading_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handelstag"])},
    "settlement_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungspreis"])},
    "day_ahead_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day-Ahead-Preis"])},
    "wind_offshore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind Offshore"])},
    "wind_onshore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind Onshore"])},
    "solar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solar"])},
    "actual_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tatsächliche Last"])},
    "forecasted_load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prognostizierte Last"])},
    "actual_generation_aggregated_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tatsächliche Erzeugung"])},
    "forecasted_generation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prognostizierte Erzeugung"])},
    "balance_delta_in_mw_imbalance_per_mwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilanz-Delta in MW, Unwucht pro MWh"])},
    "downward_dispatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwärtsregelung"])},
    "upward_dispatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufwärtsregelung"])},
    "total_downward_capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtkapazität abwärts"])},
    "total_upward_capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtkapazität aufwärts"])},
    "spread_day_ahead_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread DA"])},
    "spread_intraday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread Intraday"])},
    "spread_settlement_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spread Abrechnungspreis"])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speicherung"])},
    "upwards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufwärts"])},
    "downwards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwärts"])},
    "reserved_fcr_symmetric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserviertes FCR Symmetrisch"])},
    "reserved_afrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserviertes aFRR Hoch"])},
    "reserved_afrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserviertes aFRR Runter"])},
    "avg_reserved_fcr_symmetric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlich reserviertes FCR Symmetrisch"])},
    "sum_reserved_fcr_symmetric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe reserviertes FCR Symmetrisch"])},
    "sum_reserved_afrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe reserviertes aFRR Hoch"])},
    "sum_reserved_afrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe reserviertes aFRR Runter"])},
    "average_capacity_in_mw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche Kapazität in MW"])},
    "capacity_in_mw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapazität in MW"])},
    "sum_afrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe aFRR Hoch"])},
    "sum_afrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe aFRR Runter"])},
    "sum_mfrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe mFRR Hoch"])},
    "sum_mfrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe mFRR Runter"])},
    "sum_reserved_mfrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe reserviertes mFRR Hoch"])},
    "sum_reserved_mfrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe reserviertes mFRR Runter"])},
    "datetime_in_quarter_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit in Viertelstunden"])},
    "datetime_in_four_hour_blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit in Vier-Stunden-Blöcken"])},
    "datetime_in_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit in Tagen"])},
    "datetime_in_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit in Monaten"])},
    "datetime_in_years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit in Jahren"])},
    "datetime_in_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit in Stunden"])},
    "capacity_mw_isp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapazität MW pro ISP"])},
    "capacity_mw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapazität MW"])},
    "activated_balancing_prices_mwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivierte Ausgleichspreise MWh"])},
    "total_mwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt MWh"])},
    "max_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höchstpreis"])},
    "min_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestpreis"])},
    "max_pos_300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Pos 300"])},
    "max_pos_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Pos 100"])},
    "max_pos_600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Pos 600"])},
    "min_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min 100"])},
    "min_300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min 300"])},
    "min_600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min 600"])},
    "frequency_mhz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequenz in Hz"])},
    "charter_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$/Chartertag"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunde"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
    "pte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PTE"])},
    "end_of_day_settlement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagesendschlussabrechnung"])},
    "euro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR"])},
    "dollar_per_tonne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$/Tonne"])},
    "dollar_per_barrel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$/Barrel"])},
    "euro_per_mwh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€/MWh"])},
    "euro_per_tonne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€/Tonne"])},
    "twh_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TWh/Monat"])},
    "twh_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TWh/Jahr"])},
    "mwh_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MWh/Monat"])},
    "mwh_per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MWh/Jahr"])},
    "fn_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
    "fn_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
    "fn_avg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnitt"])},
    "ma_take_from_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleitender Durchschnitt Entnahme"])},
    "ma_feed_into_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleitender Durchschnitt Einspeisung"])},
    "take_from_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entnahme aus dem System"])},
    "feed_into_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einspeisung in das System"])},
    "activated_afrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviertes aFRR Hoch"])},
    "activated_afrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviertes aFRR Runter"])},
    "activated_mfrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviertes mFRR Hoch"])},
    "activated_mfrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiviertes mFRR Runter"])},
    "afrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Runter"])},
    "afrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aFRR Hoch"])},
    "mfrr_down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Runter"])},
    "mfrr_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mFRR Hoch"])},
    "fcr_symmetric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FCR Symmetrisch"])},
    "stacked_by_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestapelt nach Jahr"])},
    "cumulative_current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumulierter Strom"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strom"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl"])}
  },
  "page_titles": {
    "menu_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnements"])},
    "battery_simulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batteriesimulation"])},
    "batteries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batterie-Dashboard"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "dashboard_ns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard NS"])},
    "dashboard_por": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Hafenbetrieb Rotterdam"])},
    "energy_outlook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energieausblick"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlermeldung"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
    "activate_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail aktivieren"])},
    "resend_activation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivierung erneut senden"])},
    "recover_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail zurücksetzen"])},
    "return_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückkehr Abonnement"])},
    "recover_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisgestaltung"])},
    "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite nicht gefunden"])}
  },
  "gasInStorage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasspeicherung"])},
  "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederlande"])},
  "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januar"])},
  "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februar"])},
  "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["März"])},
  "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
  "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
  "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni"])},
  "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli"])},
  "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
  "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
  "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober"])},
  "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
  "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezember"])},
  "jan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])},
  "feb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feb"])},
  "mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mär"])},
  "apr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apr"])},
  "jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jun"])},
  "jul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jul"])},
  "aug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aug"])},
  "sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sep"])},
  "oct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okt"])},
  "nov": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nov"])},
  "dec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dez"])},
  "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonntag"])},
  "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])},
  "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstag"])},
  "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittwoch"])},
  "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnerstag"])},
  "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitag"])},
  "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samstag"])},
  "decimalPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([","])},
  "thousandsSep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
  "subscriptions": {
    "confirm_subscription_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung der Abonnementlöschung"])},
    "confirm_subscription_delete_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktion wird Ihr Abonnement sofort löschen, und diese Aktion ist unwiderruflich."])},
    "battery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batterie"])},
    "dashboard_and_simulator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard und simulation"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgebrochen"])},
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgebrochen"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "reference_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenz-ID"])},
    "period_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginn des Zeitraums"])},
    "period_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende des Zeitraums"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarif"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansehen"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornieren"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlt"])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungen"])},
    "current_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Abonnement"])},
    "current_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktueller Tarif"])},
    "our_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Abonnements"])},
    "choose_your_flavor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie Ihre Option"])},
    "beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfänger"])},
    "essentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundlagen"])},
    "good_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guter Start"])},
    "access_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff auf alles"])},
    "dashboards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dashboards"])},
    "receive_monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich erhalten"])},
    "analytics_newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analytischer Newsletter"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "basic_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundoptionen"])},
    "most_popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am beliebtesten"])},
    "all_previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle vorherigen"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optionen"])},
    "all_previous_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle vorherigen optionen"])},
    "custom_dashboards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuelle Dashboards"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefiniert"])},
    "first_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Erster"])},
    "new_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neue Funktionen erleben"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "and_discount_on_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und Rabatt auf Schulungen"])},
    "access_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang zu"])},
    "custom_analytics_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuelles Analysewerkzeug"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zugriff"])},
    "custom_api": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierte API"])},
    "sla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
    "support_guaranteed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support garantiert"])},
    "all_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Optionen"])},
    "first_to_experience_new_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Erster neue Funktionen erleben"])},
    "support_and_discount_on_training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support und Rabatt auf Schulungen"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
    "big_data_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big-Data-Analyse"])},
    "access_to_custom_analytics_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugang zum individuellen Analysewerkzeug"])},
    "custom_api_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff auf benutzerdefinierte API"])},
    "sla_support_guaranteed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantierter SLA-Support"])}
  },
  "homepage_highlights": {
    "energy_outlook_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehen Sie sich unseren Energieausblick an"])},
    "energy_outlook_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Energiebericht bietet einen schnellen Einblick in die aktuellen Entwicklungen auf verschiedenen Energiemärkten."])},
    "account_disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Ansehen des Energieberichts ist ein Konto erforderlich."])},
    "energy_outlook_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energieausblick"])},
    "customized_solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maßgeschneiderte Lösungen"])},
    "customized_solutions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben Erfahrung in der Verarbeitung und Umwandlung großer Datenmengen. Und darin, Daten in Wissen zu übersetzen. Gerne denken wir darüber nach, wie wir unsere Daten anbieten können, aber auch, wie eigene Unternehmensdaten präsentiert werden können."])},
    "customized_solutions_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "register_account_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung"])},
    "register_account_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bleiben Sie über unsere Entwicklungen informiert, indem Sie unseren Newsletter folgen. Oder registrieren Sie ein Konto, um unsere Energieberichte einzusehen. Wir arbeiten intensiv an verschiedenen Abonnementmodellen und neuen Funktionen."])},
    "register_account_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt Ihr Konto registrieren"])}
  }
}