import axios from 'axios'
import { SHA256, enc } from 'crypto-js'

const debug = true
const baseDomain = process.env.VUE_APP_API_BASE_URL
const baseURL = `${baseDomain}/api/v1/`
let isRefreshing = false
let subscribers = []

function onTokenRefreshed (accessToken) {
  subscribers.forEach(callback => callback(accessToken))
  subscribers = []
}

function addSubscriber (callback) {
  subscribers.push(callback)
}

const request = axios.create({ baseURL })

request.interceptors.request.use(config => {
  const accessToken = window.sessionStorage.getItem('accessToken')
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }
  return config
}, error => Promise.reject(error))

request.interceptors.response.use(response => response, async error => {
  const { config, response: { status } } = error
  if (status === 401 && !config._retry) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        addSubscriber(accessToken => {
          config.headers.Authorization = `Bearer ${accessToken}`
          resolve(request(config))
        })
      })
    }

    config._retry = true
    isRefreshing = true

    try {
      const refreshToken = window.localStorage.getItem('refreshToken')
      const response = await axios.get(`${baseURL}auth/refresh`, { headers: { Authorization: `Bearer ${refreshToken}` } })
      const accessToken = response.data.access_token
      window.sessionStorage.setItem('accessToken', accessToken)
      request.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      onTokenRefreshed(accessToken)
      return request(config)
    } catch (err) {
      console.error('Refresh Token Error:', err)
      // Handle token refresh errors (e.g., redirect to login)
    } finally {
      isRefreshing = false
    }
  }
  return Promise.reject(error)
})

export default request
