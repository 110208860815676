import AuthenticatedClient from './clients/AuthenticatedClient'
import Client from './clients/Client'

const resource = '/auth'

export default {
  activate (activationCode) {
    return Client.get(`${resource}/activate/${activationCode}`)
  },
  register (payload) {
    return Client.post(`${resource}/register`, payload)
  },
  authenticate (payload) {
    return Client.post(`${resource}/login`, payload)
  },
  recoverPassword (payload) {
    return Client.post(`${resource}/recover`, payload)
  },
  checkRecoveryCode (recoveryCode) {
    return Client.get(`${resource}/recover/${recoveryCode}`)
  },
  resetPassword (payload) {
    return Client.post(`${resource}/reset`, payload)
  },
  resendActivation (payload) {
    return Client.post(`${resource}/resend-activation`, payload)
  },
  getSubscriptions () {
    return Client.get(`${resource}/subscriptions`)
  },
  storeRouteChange (payload) {
    return Client.post(`${resource}/user/route`, payload)
  },
  storeRouteError (payload) {
    return Client.post(`${resource}/user/route/error`, payload)
  },
  protected (accessToken) {
    return AuthenticatedClient.get(`${resource}/protected`)
  },
  logout () {
    return AuthenticatedClient.get(`${resource}/logout`)
  },
  getInvoices () {
    return AuthenticatedClient.get(`${resource}/invoices`)
  },
  userSubscriptionInformation () {
    return AuthenticatedClient.get(`${resource}/user/subscription`)
  },
  userInformation () {
    return AuthenticatedClient.get(`${resource}/user`)
  },
  updateUserInformation (payload) {
    return AuthenticatedClient.post(`${resource}/user`, payload)
  },
  createUserPassword (payload) {
    return AuthenticatedClient.post(`${resource}/email_password`, payload)
  },
  updateUserPassword (payload) {
    return AuthenticatedClient.post(`${resource}/password`, payload)
  },
  cancelSubscription (id) {
    return AuthenticatedClient.get(`${resource}/subscription/cancel/${id}`)
  },
  deleteSubscription (id) {
    return AuthenticatedClient.get(`${resource}/subscription/delete/${id}`)
  },
  getCheckoutInformation (itemId) {
    return AuthenticatedClient.get(`${resource}/checkout/${itemId}`)
  },
  postCheckout (payload) {
    return Client.post(`${resource}/checkout`, payload)
  },
  getUsersAdministration (payload) {
    return AuthenticatedClient.get(`${resource}/users`)
  },
  getSubscriptionsAdministration (payload) {
    return AuthenticatedClient.get(`${resource}/subscribed`)
  }
}
