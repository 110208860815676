<template>
  <li v-if="(item.auth === undefined || (item.auth && isAuthenticated) || (!item.auth && !isAuthenticated)) && (item.subscribed === undefined || (item.subscribed && isSubscribed) || (!item.subscribed && !isSubscribed)) && (item.admin === undefined || (item.admin && isSuperUser) || (!item.admin && !isSuperUser)) ">
    <component
      :is="componentIs"
      :to="itemTo"
      :href="itemHref"
      :exact-active-class="exactActiveClass"
      class="flex cursor-pointer"
      :class="componentClass"
      @click="menuClick"
    >
      <icon
        v-if="item.icon"
        :path="item.icon"
        :update-mark="item.updateMark ?? null"
        :update-mark-tr="updateMarkTr"
        class="flex-none"
        :w="menuIconWidth"
      />
      <span class="flex-grow" :class="{'lg:hidden':compact}">{{ $t(item.label) }}</span>
      <icon v-if="hasSub" :path="subIcon" class="flex-none" w="w-12" :class="{'lg:hidden':compact}" />
    </component>
    <aside-menu-list
      v-if="hasDropdown"
      :menu="item.menu"
      :class="{ 'hidden': !isDropdownActive, 'block bg-gray-600 dark:bg-gray-800': isDropdownActive }"
      is-submenu-list
    />
  </li>
</template>

<script>
import { defineAsyncComponent, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { mdiMinus, mdiPlus, mdiChevronRight } from '@mdi/js'
import { colorsButtons } from '@/colors.js'
import Icon from '@/components/Icon'

export default {
  name: 'AsideMenuItem',
  components: {
    AsideMenuList: defineAsyncComponent(() => import('@/components/AsideMenuList')),
    Icon
  },
  emits: ['menu-click'],
  props: {
    item: Object,
    isSubmenuList: Boolean,
    compact: Boolean
  },
  setup (props, { emit }) {
    const store = useStore()
    const isAuthenticated = computed(() => store.state.auth.isAuthenticated)
    const isSuperUser = computed(() => store.state.auth.isSuperUser)
    const isSubscribed = computed(() => store.state.auth.isSubscribed)

    const isAsideCompact = computed(() => store.state.display.isAsideCompact)

    watch(isAsideCompact, value => {
      if (value) {
        isDropdownActive.value = false
      }
    })

    const isDropdownActive = ref(false)

    const isSecondaryMenuActive = computed(() => secondaryMenu.value && props.item && props.item.key === secondaryMenu.value.key)

    const secondaryMenu = computed(() => store.state.display.secondaryMenu)

    const hasColor = computed(() => props.item && props.item.color && colorsButtons[props.item.color])

    const activeClass = 'bg-gray-700 dark:bg-gray-800'

    const componentClass = computed(() => {
      const base = [
        props.isSubmenuList ? 'p-3 text-sm' : 'py-2',
        hasColor.value ? colorsButtons[props.item.color] : 'text-gray-300 hover:bg-gray-700 hover:dark:bg-gray-700'
      ]

      if (!hasColor.value && (isDropdownActive.value || isSecondaryMenuActive.value)) {
        base.push(activeClass)
      }

      return base
    })

    const exactActiveClass = computed(() => secondaryMenu.value ? null : activeClass)

    const componentIs = computed(() => props.item.to ? 'router-link' : 'a')

    const hasDropdown = computed(() => !!props.item.menu)

    const hasSecondary = computed(() => !!props.item.menuSecondary)

    const hasSub = computed(() => hasDropdown.value || hasSecondary.value)

    const subIcon = computed(() => {
      if (hasSecondary.value) {
        return mdiChevronRight
      }

      return isDropdownActive.value ? mdiMinus : mdiPlus
    })

    const itemTo = computed(() => props.item.to || null)

    const itemHref = computed(() => props.item.href || null)

    const menuClick = event => {
      if ('action' in props.item && props.item.action === 'showNewsletter') {
        store.dispatch('display/newsletterModalToggle', true)
      } else {
        emit('menu-click', event, props.item)
      }

      if (hasDropdown.value) {
        isDropdownActive.value = !isDropdownActive.value
      }
    }

    const menuIconWidth = computed(() => props.compact ? 'w-12 lg:w-20' : 'w-12')

    const updateMarkTr = computed(() => {
      const base = 'top-0 right-2'

      return props.compact ? `${base} lg:right-6` : base
    })

    return {
      isAsideCompact,
      isDropdownActive,
      isSecondaryMenuActive,
      componentClass,
      exactActiveClass,
      componentIs,
      hasDropdown,
      hasSecondary,
      hasSub,
      subIcon,
      itemTo,
      itemHref,
      menuClick,
      menuIconWidth,
      updateMarkTr,
      isAuthenticated,
      isSubscribed,
      isSuperUser
    }
  }
}
</script>
