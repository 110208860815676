import { createApp } from 'vue'

import App from './App.vue'
// import JsonCsv from 'vue-json-csv'
import router from './router'
import store from './store'

import { useI18n } from 'vue-i18n'
import offlineExporting from 'highcharts/modules/offline-exporting'
import HighchartsHeatmap from 'highcharts/modules/heatmap'
import SolidGauge from 'highcharts/modules/solid-gauge'
import HighchartsMore from 'highcharts/highcharts-more'
import exportData from 'highcharts/modules/export-data'
import exporting from 'highcharts/modules/exporting'

import VueApexCharts from 'vue3-apexcharts'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import VueGtag from 'vue-gtag'

import Moment from 'moment'
import MomentTimeZone from 'moment-timezone'

/* Service Worker */
import './registerServiceWorker'
import clickOutsideDirective from './directives/clickOutsideDirective'

// Global Components
import CardComponent from '@/components/CardComponent'
import './css/main.css'
import i18n from './i18n'

const keys = {
  months: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
  shortMonths: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
  weekdays: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
}

const getTranslations = key => keys[key].map(item => i18n.global.t(item))

Highcharts.setOptions({
  lang: {
    decimalPoint: i18n.global.t('decimalPoint'),
    thousandsSep: i18n.global.t('thousandsSep'),
    months: getTranslations('months'),
    shortMonths: getTranslations('shortMonths'),
    weekdays: getTranslations('weekdays')
  }
})

// import moment from 'moment'
MomentTimeZone()
HighchartsHeatmap(Highcharts)
offlineExporting(Highcharts)
HighchartsMore(Highcharts)
exportData(Highcharts)
SolidGauge(Highcharts)
exporting(Highcharts)

/* Responsive layout control */
store.dispatch('display/responsiveLayoutControl')
window.onresize = () => store.dispatch('display/responsiveLayoutControl')

/* Fetch sample data */
// store.dispatch('data/fetch', 'countries')

/* Uncomment to make dark default */
// store.dispatch('display/darkMode')
store.dispatch('display/defaultLocale')
store.dispatch('display/defaultDarkMode')

/* Default title tag */
const defaultDocumentTitle = 'Profiteia.io'

/* Collapse mobile aside menu on route change & set document title from route meta */
router.beforeEach(to => {
  store.dispatch('display/asideMobileToggle', false)
  store.dispatch('display/secondaryMenuToggle', null)

  if (store.state.display.isAsideHiddenLayout) {
    store.dispatch('display/asideVisibilityToggle', false)
  }
  
  store.dispatch('display/formScreenToggle', !!to.meta.formScreen)
})

router.afterEach(to => {
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(HighchartsVue)
  .use(VueGtag, {
    config: { id: 'G-JNFHXTC5TR' }
  })

app.component('CardComponent', CardComponent)
app.mount('#app')
