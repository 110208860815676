<template>
  <footer v-show="isFooterBarVisible" class="bg-white py-2 px-6 dark:bg-gray-900">
    <level>
      <div>
        &copy; {{ year }} - profiteia.io
      </div>
      <div class="md:py-2">
        <profiteia-logo-text class="h-4" />
      </div>
    </level>
  </footer>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Level from '@/components/Level'
import ProfiteiaLogoText from '@/components/ProfiteiaLogoText'

export default {
  name: 'FooterBar',
  components: {
    Level,
    ProfiteiaLogoText
  },
  setup () {
    const store = useStore()

    const year = computed(() => new Date().getFullYear())

    const isFooterBarVisible = computed(() => !store.state.display.isFormScreen)

    return {
      year,
      isFooterBarVisible
    }
  }
}
</script>
