import { mdiEmailNewsletter } from '@mdi/js'

export default [
  {
    label: 'menu_subscribe',
    action: 'showNewsletter',
    icon: mdiEmailNewsletter,
    prevent: true,
    color: 'success'
  }
]
